import React from 'react';
import Popover from '@mui/material/Popover';
import AssetForm from '../features/asset-form-panel/AssetForm';
import DeleteAssetButton from './DeleteAssetButton';

const UpdateAssetPopover = ({ id, open, anchorEl, onClose, asset, updateAsset, excludedProperties = [], handleDeleteAsset = () => { } }) => {
    return (
        <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            slotProps={{
                paper: {
                    style: { width: '20%' }
                }
            }}
        >
            <span> {asset.asset_uuid || asset.id}</span>
            <DeleteAssetButton asset={asset}
                onDeleteAction={handleDeleteAsset} />
            <AssetForm asset={asset}
                updateAsset={updateAsset}
                excludedProperties={excludedProperties} />
        </Popover>
    );
};

export default UpdateAssetPopover;
