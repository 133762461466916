import React, { useRef, useState, useEffect, useContext } from "react";
import {
    Tooltip,
    Button,
} from "@mui/material";
import HighlightAltIcon from "@mui/icons-material/HighlightAlt";
import ClearIcon from "@mui/icons-material/Clear";
import DownloadIcon from "@mui/icons-material/Download";
import SaveIcon from '@mui/icons-material/Save';
import {
    mapService
} from "../../../services";
import WorkAreaDialog from "./WorkAreaDialog";
import { StoreContext } from "../../../Contexts";
import { AssetApi } from "../../../api";

const PolygonTools = (
    { currentMap,
        currentLayers,
        layerStates,
        setLayerStates,
        defaultLayerState }, ...props) => {
    const { Maps } = window.Microsoft;
    const appContext = useContext(StoreContext);
    const [, setLoading] = appContext.loading;
    const [, setSnackbar] = appContext.snackbar;
    const [, setPolesUpdateTrigger] = appContext.polesUpdateTrigger;
    const [tools, setTools] = useState(null);
    const toolsRef = useRef();
    toolsRef.current = tools;
    const [polygonDefined, setPolygonDefined] = useState();

    const [selectedArea, setSelectedArea] = useState();
    const selectedAreaRef = useRef();
    selectedAreaRef.current = selectedArea;

    const [selectedAssets, setSelectedAssets] = useState();
    const selectedAssetsRef = useRef([]);
    selectedAssetsRef.current = selectedAssets;

    const [modalOpen, setModalOpen] = useState(false);
    const openModal = () => {
        setModalOpen(true);
    }

    const drawPolygon = () => {
        //Create a new polygon.
        toolsRef.current.create(Maps.DrawingTools.ShapeType.polygon, function (s) {
            setSelectedArea(s);
        });
        Object.entries(layerStates).forEach(([layerKey, layerState]) => {
            layerState.enabled = layerKey.startsWith('pole') || layerKey === 'workareas';
        })
        setTools(toolsRef.current);
        setLayerStates(prevState => ({
            ...prevState,
            ...layerStates
        }))
    }

    const clearSelectedArea = () => {
        toolsRef.current.finish();
        setPolesUpdateTrigger(prev => !prev);
        setSelectedAssets(undefined);
        setSelectedArea(undefined);
        setPolygonDefined(false);
        Object.keys(layerStates).forEach((layerKey) =>
            layerStates[layerKey].enabled = defaultLayerState[layerKey].enabled
        )
        setLayerStates(prevState => ({
            ...prevState,
            ...layerStates
        }));
    }

    const exportAssets = async () => {
        setLoading(true);
        try {
            await AssetApi.exportPoles(selectedAssets.map(asset => asset.asset_id));
        }
        catch (error) {
            setSnackbar({
                open: true,
                message: error.message,
                severity: 'error',
            });
        }
        setLoading(false);
    }

    useEffect(() => {
        const initPolygonTools = () => {
            Maps.loadModule(['Microsoft.Maps.DrawingTools', 'Microsoft.Maps.SpatialMath'], function () {
                let drawingTools = new Maps.DrawingTools(currentMap);
                Maps.Events.addHandler(drawingTools, 'drawingChanged', function (s) {
                    let assets = mapService.findPinsInArea(currentLayers.poles3, s, 'black', 'purple');
                    assets
                        ? setSelectedAssets(assets
                            .map(asset => { return { asset_id: asset.id || asset.asset_uuid } }))
                        : setSelectedAssets(undefined);
                });

                Maps.Events.addHandler(drawingTools, 'drawingModeChanged', function (s) {
                    s.mode === 7
                        ? setPolygonDefined(true)
                        : setPolygonDefined(false);
                });
                setTools(drawingTools);
            });
        }

        initPolygonTools();
    }, [Maps, currentLayers.poles3, currentMap]);

    return (
        <>
            <div>
                {selectedAreaRef.current ?
                    <Tooltip title="Clear Selected Poles" arrow>
                        <Button
                            sx={{ background: 'rgba(0,0,0,0.6)', color: 'white' }}
                            aria-label="clear selected"
                            onClick={() => { clearSelectedArea('poles', 'grey') }}>
                            <ClearIcon />
                        </Button>
                    </Tooltip> :
                    <Tooltip title="Select Poles" arrow>
                        <Button
                            sx={{ background: 'rgba(0,0,0,0.6)', color: 'white' }}
                            aria-label="select pins"
                            onClick={drawPolygon}>
                            <HighlightAltIcon />
                        </Button>
                    </Tooltip>
                }
                {polygonDefined &&
                    <>
                        <Tooltip title="Download Selected Poles" arrow>
                            <Button
                                sx={{ background: 'rgba(0,0,0,0.6)', color: 'white' }}
                                aria-label="download selected poles"
                                onClick={exportAssets} >
                                <DownloadIcon />
                            </Button>
                        </Tooltip>
                        <Tooltip title="Save Workarea" arrow>
                            <Button
                                sx={{ background: 'rgba(0,0,0,0.6)', color: 'white' }}
                                aria-label="save selected area"
                                onClick={openModal} >
                                <SaveIcon />
                            </Button>
                        </Tooltip>
                    </>}
            </div>
            {modalOpen && <WorkAreaDialog
                modalOpen={modalOpen}
                setModalOpen={setModalOpen}
                selectedArea={selectedAreaRef.current}
                selectedAssets={selectedAssetsRef.current}
                setSelectedAssets={setSelectedAssets}
                clearSelectedArea={clearSelectedArea}
                drawingTools={toolsRef.current} />
            }
        </>)
}

export default PolygonTools;