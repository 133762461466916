import { PanoramaApi } from "../api";

async function getPanoramas(collection_name, bounds) {
    // get the imagery in the area. 
    const imageryInArea = await PanoramaApi.fetchImageryByBounds(
        collection_name,
        bounds.northEast.latitude,
        bounds.northEast.longitude,
        bounds.southWest.latitude,
        bounds.southWest.longitude
    );

    return imageryInArea.length < 200 ? imageryInArea : [];
}

export {
    getPanoramas
}