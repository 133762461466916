/* eslint-disable */
import { IconLayer } from '@deck.gl/layers';


function initializeMapSettings(mapData) {
  const ICON_MAPPING = {
    marker: {x: 0, y: 0, width: 128, height: 128, mask: true}
  };
  const iconLayer = new IconLayer({
      id: 'icon-layer',
      data: mapData.poles,
      pickable: true,
      // iconAtlas and iconMapping are required
      // getIcon: return a string
      iconAtlas: 'https://raw.githubusercontent.com/visgl/deck.gl-data/master/website/icon-atlas.png',
      iconMapping: ICON_MAPPING,
      getIcon: d => 'marker',

      sizeScale: 15,
      getPosition: d => d.coordinates,
      getSize: d => 5,
      getColor: d => [Math.sqrt(d.exits), 140, 0],
      onClick: (info, event) => {
        console.log("info:")
        console.log(info.object);
      }
    });


  const MAP_CONFIGURATION = {
    // Viewport settings
    initialViewState : {
      longitude: -122.3321,
      latitude: 47.6062,
      zoom: 11.5,
      pitch: 0,
      bearing: 0
    },
    mapBox: {
      accessToken: "pk.eyJ1Ijoic3BlbmNlcmNvb2xleTEiLCJhIjoiY2xocWVuYno2MGNpeTNobzY4aXlzY2V0aSJ9.RZUMJ-QYRtQKzPuHqCnqaw",
      mapStyles: {
        light: "mapbox://styles/mapbox/light-v11",
        dark: "mapbox://styles/mapbox/dark-v11"
      }
    },
    layers: [iconLayer]
  }

  return MAP_CONFIGURATION;

}

export default initializeMapSettings;