export const DropdownValueType = {
    PoleHeight: {
        type: 'pole_height',
        valueKey: 'height',
        labelKey: 'height',
    },
    PoleMaterial: {
        type: 'pole_material',
        valueKey: 'material',
        labelKey: 'material'
    },
    PoleClass: {
        type: 'pole_class',
        valueKey: 'class',
        labelKey: 'class'
    },
    PoleSpecies: {
        type: 'pole_species',
        valueKey: 'acronym',
        labelKey: 'species'
    },
    PoleGround: {
        type: 'pole_ground',
        valueKey: 'ground',
        labelKey: 'ground'
    },
    GuyAnchorDiameter: {
        type: 'pole_anchor_diameter',
        valueKey: 'diameter',
        labelKey: 'diameter'
    },
    DownGuyType: {
        type: 'pole_guy_diameter',
        valueKey: 'diameter',
        labelKey: 'diameter'
    },
    AnchorOwner: {
        type: 'pole_guy_anchor_owner',
        valueKey: 'owner',
        labelKey: 'owner'
    },
    SidewalkBraceType: {
        type: 'pole_guy_sidewalkbrace',
        valueKey: 'brace_type',
        labelKey: 'brace_type'
    }
}