export const InteractiveSection = {
  AssetInfoHeader: 'asset-info-header',
  AssetInfoPanel: 'asset-info-panel',
  PoleInfoPanel: 'pole-info-panel',
  PoleFormHeader: 'pole-form-header',
  AssetFormPanel: 'asset-form-panel',
  AssetInputGrid: 'asset-input-grid',
  RequestGroundCollection: 'request-ground-collection',
  Map: 'map',
  Panorama: 'panorama',
  PointCloud: 'point-cloud',
  Dummy: 'dummy',
  MeasureTools: 'generic-measure-tools',
  AddPoleButton: 'add-pole-button'
};  