/* eslint-disable */

import ArcMap from "./ArcMap";
import BingMap from './BingMap';

const bingApiKey = "AtBiHX1SI8HUvf2IfHV_a26ardSJffXmgCIhmcw-ko6okLLxb8oPgDDwZ8ycoPKk";

const BASE = "bing";

function MapStage() {
  if (BASE === "bing") { 
    return (
      <BingMap bingMapsKey={bingApiKey}></BingMap>
    )
  }


  // if (BASE === "mapbox") {
  //   return (
  //     <MapBoxBase></MapBoxBase>
  //   );
  // }


  if (BASE === "arcgis") {
    return (
      <ArcMap></ArcMap>
    );
  }
}

export default MapStage;
