const authorizationToken = "effffdb1-3f53-414d-a200-d3a6bb4b5bc4";
const baseUrl = `https://t3sij95115.execute-api.us-east-1.amazonaws.com/uat`;

const fetchPointCloudsByBounds = async (collectionName, gpsx1, gpsy1, gpsx2, gpsy2) => {
    if (gpsx1) {
        const url = `${baseUrl}/get_pointcloud_data_boundingbox?project_name=${collectionName}&gpsx1=${gpsx1}&gpsy1=${gpsy1}&gpsx2=${gpsx2}&gpsy2=${gpsy2}`;
        return await fetch(url)
            .then(response => response.json())
            .then(data => { return data.body })
            .catch(error => { throw new Error(`API call failed with status code ${error}`) });
    }
}

const fetchPointCloudsByProject = async (projectName) => {
    const url = `${baseUrl}/get_pointcloud_data_by_project?project_name=${projectName}`;
    return await fetch(url)
        .then(response => response.json())
        .then(data => { return data.body })
        .catch(error => { throw new Error(`API call failed with status code ${error}`) });
}


const fetchPointCloudByGPSLocation = async (collectionName, latitude, longitude) => {
    const url = `${baseUrl}/get_pointcloud_by_gps_location?collection_name=${collectionName}&lat=${latitude}&lng=${longitude}`;
    return await fetch(url)
        .then(response => response.json())
        .then(data => { return data.body })
        .catch(error => { throw new Error(`API call failed with status code ${error}`) });
}

const fetchPointcloudMetadataJsonUrl = async (collection_name, collection_id, collection_set, point_cloud_filename) => {
    const url = `${baseUrl}/get_pointcloud_potree_view?collection_name=${collection_name}&collection_id=${collection_id}&collection_set=${collection_set}&point_cloud_filename=${point_cloud_filename}`;
    return await fetch(url)
        .then(response => response.json())
        .then(data => { return data.body })
        .catch(error => { throw new Error(`API call failed with status code ${error}`) });
}

const fetchAdjacentPointcloudTiles = async (pointcloudId, numberOfTiles) => {
    const url = `${baseUrl}/get_pointcloud_potree_view_by_id?point_cloud_id=${pointcloudId}&adjacent_tiles=${numberOfTiles}`;
    return await fetch(url)
        .then(response => response.json())
        .then(data => { return data.body })
        .catch(error => { throw new Error(`API call failed with status code ${error}`) });
}

export {
    fetchPointCloudsByBounds,
    fetchPointCloudsByProject,
    fetchPointCloudByGPSLocation,
    fetchPointcloudMetadataJsonUrl,
    fetchAdjacentPointcloudTiles
}