import React, { useContext } from "react";
import { StoreContext } from "../Contexts";
import CustomChip from "./CustomChip";

const AssetInfoChip = () => {
    const appContext = useContext(StoreContext);
    const [activePole] = appContext.activePole;
    return (
        <CustomChip
            backgroundcolor={activePole.workflow_status_color ?? 'default'}
            textcolor='black'
            label={(
                <section>
                    <div> {`Pole ID: ${activePole.asset_uuid || activePole.id}`}</div>
                    {activePole.workflow_status &&
                        <div> {`Status: ${activePole.workflow_status}`}</div>}
                    {/* {activePole.assignee &&
                        <div> {`Assignee: ${activePole.assginee}`}</div>}
                    <div> {`Updated: ${activePole.date_updated}`}</div> */}
                </section>
            )}
            sx={{
                height: 'auto',
                '& .MuiChip-label': {
                    display: 'block',
                    whiteSpace: 'normal',
                },
            }} />
    )
}

export default AssetInfoChip;