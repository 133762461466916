import Ajv2019 from "ajv/dist/2019"
import { InputType } from "../Enums";

const validateJson = (schema, jsonObject) => {
    const ajv = new Ajv2019({ allErrors: true, strict: false });
    const validate = ajv.compile(schema);
    const valid = validate(jsonObject);
    if (!valid) {
        return validate.errors;
    }
}

const handleNestedCoordinateProperties = (measurementProperties, includedTableMeasurementTypes, properties, parentKey = '') => {
    Object.entries(properties).forEach(([key, prop]) => {
        const currentKey = parentKey ? `${parentKey}.${key}` : key;

        if (prop.type === InputType.Object
            && prop.object_format === "pointcloud_coordinates_object"
            && prop.measurement_types) {
            prop.measurement_types.forEach(measurement => {
                if (includedTableMeasurementTypes.includes(measurement.measurement_type)) {
                    if (!measurementProperties[measurement.measurement_type]) {
                        measurementProperties[measurement.measurement_type] = [];
                    }
                    const existingEntryIndex = measurementProperties[measurement.measurement_type].findIndex(entry => entry.key === currentKey);
                    if (existingEntryIndex === -1) {
                        measurementProperties[measurement.measurement_type].push({ key: currentKey, properties: prop });
                    }
                }
            });
        }
        else if (prop.type === InputType.Array && prop.minItems) {
            if (key === 'attached_assets') {
                handleNestedCoordinateProperties(measurementProperties, includedTableMeasurementTypes, prop.items.properties, `${currentKey}[0]`);
            }
            else {
                for (let i = 0; i < prop.minItems; i++) {
                    handleNestedCoordinateProperties(measurementProperties, includedTableMeasurementTypes, prop.items.properties, `${currentKey}[${i}]`);
                }
            }
        }
        else if (prop?.items?.properties) {
            handleNestedCoordinateProperties(measurementProperties, includedTableMeasurementTypes, prop.items.properties, currentKey);
        }
    });
};

export { validateJson, handleNestedCoordinateProperties };