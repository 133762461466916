import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { CircularProgress, Container } from '@mui/material';

const columnDefs = [
    {
        field: 'asset_id',
        headerName: 'ID',
        width: 80,
    },
    {
        field: 'assignee',
        headerName: 'Assignee',
        width: 200,
    },
    {
        field: 'workflow_status',
        headerName: 'Workflow Status',
        width: 200,
    },
];

const AssetsWorkflowDataGrid = ({ rows, getRowId, rowSelectionModel, onRowSelectionModelChange }) => {
    if (rows && !rows.length) {
        return (
            <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                No Assets associated with wrokarea.
            </Container>
        )
    }
    if (!rows) {
        return (
            <Container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                <CircularProgress />
            </Container>
        )
    }
    return (
        <DataGrid
            loading={!rows}
            rows={rows || []}
            getRowId={getRowId}
            columns={columnDefs}
            initialState={{
                pagination: {
                    paginationModel: { page: 0, pageSize: 5 },
                },
            }}
            pageSizeOptions={[5, 10]}
            checkboxSelection
            onRowSelectionModelChange={onRowSelectionModelChange}
            rowSelectionModel={rowSelectionModel}
        />
    );
};

export default AssetsWorkflowDataGrid;