/* eslint-disable */
import React, { useContext, useEffect } from "react";
import Stage from '../features/stage/Stage';
import { PositionContext, StoreContext } from '../Contexts';
import { useLocation, useNavigate } from "react-router-dom";
import { PointCloudApi, ProjectApi } from "../api";
import { InteractiveSection, LayoutOption } from "../Enums";
import { assetService } from "../services";

const PointcloudOne = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const collection_name = queryParams.get("collection_name");
  const query_latitude = queryParams.get('lat');
  const query_longitude = queryParams.get('lng');
  const pole_id = queryParams.get("pole_id");
  const image_id = queryParams.get("image_id");
  const appContext = useContext(StoreContext);
  const positionContext = useContext(PositionContext);
  const [, setLayout] = appContext.layout;
  const [, setMainView] = appContext.mainView;
  const [, setSidebarModules] = appContext.sideBarModules;
  const [, setActivePointcloud] = appContext.activePointcloud;
  const [activeProject, setActiveProject] = appContext.activeProject;
  const [, setActivePole] = appContext.activePole;
  const [, setImageId] = appContext.imageId;
  const [, setSnackbar] = appContext.snackbar;
  const [, setLatitude] = positionContext.latitude;
  const [, setLongitude] = positionContext.longitude;

  const fetchData = async () => {
    const selectedPointCloudData = (await PointCloudApi
      .fetchPointCloudByGPSLocation(collection_name, query_latitude, query_longitude))[0];
    if (pole_id) {
      try {
        let selectedPole = await assetService.fetchAsset(pole_id);
        const sceneData = selectedPole.asset_properties?.pointcloud_scene_data;
        const pointcloud_def = selectedPole.asset_properties?.pointcloud_def;

        setActivePole(selectedPole);
        setActivePointcloud({
          collection_set: selectedPointCloudData.collection_set,
          collection_id: selectedPointCloudData.collection_id,
          collection_name,
          crs: sceneData ? pointcloud_def.crs : selectedPointCloudData.crs,
          pointcloud_id: sceneData ? pointcloud_def.pointcloud_id : selectedPointCloudData.id,
          classified: Boolean(selectedPointCloudData.classified)
        });
      }
      catch (e) {
        const currentPath = window.location.pathname;
        queryParams.delete('pole_id');
        navigate(`${currentPath}?${queryParams}`);
        setSnackbar({
          open: true,
          message: `Pole ${pole_id} not found`,
          severity: 'error'
        })
      }
    }
    else {
      setActivePointcloud({
        collection_set: selectedPointCloudData.collection_set,
        collection_id: selectedPointCloudData.collection_id,
        collection_name,
        crs: selectedPointCloudData.crs,
        pointcloud_id: selectedPointCloudData.id,
        classified: Boolean(selectedPointCloudData.classified)
      });
    }
    if (image_id) {
      setImageId(parseInt(image_id));
    }
    setLatitude(query_latitude);
    setLongitude(query_longitude);
  }

  const getActiveProject = async (collectionName) => {
    // if activeProject is not set then set it. we need the project id for certain things. 
    if (activeProject === null) {
      const projectList = await ProjectApi.fetchProjectList();
      // find the active project and set it to the state so we can use it in future interactions.
      const theActiveProject = projectList.find(project => project.collection_name === collectionName);
      setActiveProject(theActiveProject);
      return theActiveProject;
    }
    return null;
  }

  useEffect(() => {
    setLayout(LayoutOption.SideBars);;
    setMainView(InteractiveSection.PointCloud);
    setSidebarModules({
      left_header: InteractiveSection.MeasureTools,
      left_top: InteractiveSection.Panorama,
      left_bottom: InteractiveSection.Map,
      right_header: InteractiveSection.PoleFormHeader,
      right_top: InteractiveSection.AssetFormPanel,
      right_bottom: null
    });
    getActiveProject(collection_name);
    fetchData();
  }, []);

  return (
    <div className="stage">
      <Stage />
    </div>
  );
}

export default PointcloudOne;