import React, { useContext, useEffect, useState } from "react";
import { MapLayerContext } from "../../../Contexts";
import { mapService } from "../../../services";
import { AssetWorkflowApi, WorkareaApi } from "../../../api";
import WorkAreaDialog from "../map-tools/WorkAreaDialog";

const layerId = 'workareas';

const WorkareasLayer = (
    { map,
        visible,
        layerRef,
        updateLayer,
        workareaPolesLayerRef }, ...props) => {
    const { Maps } = window.Microsoft;
    const mapLayerContext = useContext(MapLayerContext);
    const [workareas, setWorkareas] = mapLayerContext.workareas;
    const [selectedPolygon, setSelectedPolygon] = useState();
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedAssets, setSelectedAssets] = useState();

    const openModal = () => {
        setModalOpen(true);
    }

    const clearSelectedArea = () => {
        setSelectedAssets(undefined);
        setSelectedPolygon(undefined);
    }

    const updateWorkareas = (mapRef) => {
        updateLayer(layerId, null);
        let layer = mapService.initLayer(layerId, mapRef, visible);
        layer.clear();
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (workareas) {
            workareas.forEach((workarea) => {
                let ring = [];
                workarea.polygon.map(coords => {
                    if (coords.latitude && coords.longitude) {
                        ring.push(new Maps.Location(coords.latitude, coords.longitude))
                    }
                })

                //Create a polygon
                if (ring.length) {
                    var polygon = new Maps.Polygon(ring, {
                        fillColor: workarea.assignee !== currentUser.username ? 'rgba(0, 0, 0, 0.5)' : 'rgba(210, 210, 210, 0.5)',
                    });
                    polygon.customData = workarea;
                    Maps.Events.addHandler(polygon, 'click', async (e) => {
                        e.handled = true;
                        setSelectedPolygon(polygon);
                        openModal();
                        let workareaAssets = await AssetWorkflowApi.getAssetsByKeyValue('workarea_id', workarea.workarea_id);
                        let assets = mapService.findPinsInArea(workareaPolesLayerRef, polygon, 'black', 'purple');
                        combineAndSelectAssets(assets, workareaAssets);

                    })
                    //Add the polygon to map
                    layer.add(polygon);
                }
            });
        }
        updateLayer(layerId, layer);
    }

    function combineAndSelectAssets(assets, workareaAssets) {
        const selectedAssetsMap = new Map();

        assets?.forEach(asset => {
            const assetId = asset.customData.asset_uuid || asset.asset_uuid;
            const workareaAsset = workareaAssets.find(wa => wa.asset_id === assetId);
            if (workareaAsset) {
                selectedAssetsMap.set(assetId, workareaAsset);
            } else {
                selectedAssetsMap.set(assetId, { asset_id: assetId });
            }
        });

        workareaAssets.forEach(wa => {
            if (!selectedAssetsMap.has(wa.asset_id)) {
                selectedAssetsMap.set(wa.asset_id, wa);
            }
        });

        // Convert the Map values to an array, as the final selectedAssets
        setSelectedAssets(Array.from(selectedAssetsMap.values()));
    }


    useEffect(() => {
        const fetchWorkareas = async () => {
            Maps.Events.addOne(map, 'viewrendered', async () => {
                await setWorkareas(await WorkareaApi.fetchWorkareas());
            });
        }
        if (map) {
            fetchWorkareas();
        }
    }, [map])

    useEffect(() => {
        if (map) {
            updateWorkareas(map);
        }
    }, [workareas])

    useEffect(() => {
        if (layerRef) {
            layerRef.setVisible(visible);
        }
    }, [visible, layerRef])

    return (selectedPolygon?.customData &&
        <WorkAreaDialog
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            selectedArea={selectedPolygon}
            selectedAssets={selectedAssets}
            setSelectedAssets={setSelectedAssets}
            clearSelectedArea={clearSelectedArea} />
    )
}

export default WorkareasLayer;