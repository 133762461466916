import React, { useContext, useState, useEffect } from "react";
import {
    IconButton,
    Container,
    Tooltip,
    Box
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { StoreContext } from '../../Contexts';
import { PointCloudApi } from '../../api';
import { useNavigate } from 'react-router-dom';
import { LayoutOption } from '../../Enums';
import { openPointCloud } from '../../utils/PointCloudUtils';
import DeletePoleButton from '../../custom-components/DeletePoleButton';
import AssetInfoChip from '../../custom-components/AssetInfoChip';

const AssetInfoHeader = () => {
    const navigate = useNavigate();
    const appContext = useContext(StoreContext);
    const [activePole] = appContext.activePole;
    const [activeProject] = appContext.activeProject;
    const [mainView] = appContext.mainView;
    const [, setSidebarModules] = appContext.sideBarModules;
    const [, setLayout] = appContext.layout;
    const [loading, setLoading] = appContext.loading;
    const [, setActivePointCloud] = appContext.activePointcloud;

    const closePanel = () => {
        setSidebarModules(prevState => ({
            ...prevState,
            right_top: null,
            right_header: null,
        }));
        setLayout(LayoutOption.FullPage);
    }

    const editPole = async () => {
        setLoading(true);
        const selectedPointCloudData = await PointCloudApi
            .fetchPointCloudByGPSLocation(activeProject.collection_name, activePole.y_latitude ?? activePole.lat, activePole.x_longitude ?? activePole.lng);
        await openPointCloud(setActivePointCloud,
            mainView,
            navigate,
            selectedPointCloudData[0],
            selectedPointCloudData[0].central_lat,
            selectedPointCloudData[0].central_lng,
            activePole.asset_uuid ?? activePole.id);
    }

    useEffect(() => {
        if (activePole) {
            setLoading(false);
        } else {
            setLoading(true);
        }
    }, [activePole]);

    if (!activePole) {
        return null;
    }

    return (
        <Container sx={{
            display: 'flex',
            alignItems: 'center',
            position: 'relative',
            p: 0
        }}>
            <Box sx={{ position: 'absolute', right: 0, top: 0, display: 'flex' }}>
                <Tooltip title="Close" arrow>
                    <IconButton size="small" onClick={closePanel} sx={{ ml: 1 }}>
                        <CloseIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Edit Pole" arrow>
                    <IconButton size="small" color="primary" onClick={editPole} sx={{ ml: 1 }}>
                        <EditIcon />
                    </IconButton>
                </Tooltip>
                <DeletePoleButton loading={loading} onDeleteAction={closePanel} />
            </Box>
            <AssetInfoChip />
        </Container>
    );
}

export default AssetInfoHeader;