import React, { useState } from "react";
import {
    Menu,
    MenuItem,
    Tooltip,
    Switch,
    Button,
} from "@mui/material";
import LayersIcon from "@mui/icons-material/Layers";
import LayersClearIcon from "@mui/icons-material/LayersClear";
import { MapUtils } from "../../../utils";

const MapLayerTools = (
    { currentLayers,
        layerStates,
        setLayerStates }, ...props) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleToggleLayersClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const toggleLayer = (layerKey) => {
        layerStates[layerKey].enabled = !layerStates[layerKey].enabled;
        setLayerStates((prevState) => ({
            ...prevState,
            [layerKey]: layerStates[layerKey]
        }))
    }

    const clearLayers = () => {
        Object.keys(layerStates).forEach((layerKey) =>
            layerStates[layerKey].enabled = false
        )
        setLayerStates({ ...layerStates });
    }

    return (
        <div>
            <Tooltip title="Clear Layers" arrow>
                <Button
                    sx={{ background: 'rgba(0,0,0,0.6)', color: 'white' }}
                    aria-label="clear layers"
                    onClick={clearLayers}>
                    <LayersClearIcon />
                </Button>
            </Tooltip>
            <Tooltip title="Toggle Layers" arrow>
                <Button
                    sx={{ background: 'rgba(0,0,0,0.6)', color: 'white' }}
                    aria-label="toggle layers"
                    onClick={handleToggleLayersClick}>
                    <LayersIcon />
                </Button>
            </Tooltip>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}>
                {Object.keys(currentLayers).map((layer) => {
                    return layerStates[layer] &&
                        <MenuItem key={layer} style={{ display: 'flex', alignItems: 'center' }}>
                            <Switch
                                size='small'
                                checked={layerStates[layer].enabled}
                                onChange={() => toggleLayer(layer)}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                            <span style={{ marginRight: 8 }}>{layerStates[layer].label}</span>
                            {MapUtils.getMapIcon(layer)}
                        </MenuItem>
                })}
            </Menu>
        </div>
    )
}

export default MapLayerTools;