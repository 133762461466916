import { measurementTools } from "../utils/reusable-functions";

const distributionMeasurementTypes = [{
    title: 'existing',
    id: 'distribution_existing',
    getMeasurement: function (measurement, pole_base) {
        return measurementTools.getHeight(measurement, pole_base) + 'ft '
    }
},
{
    title: 'proposed',
    id: 'distribution_proposed',
    getMeasurement: function (measurement, pole_base) {
        return measurementTools.getHeight(measurement, pole_base) + 'ft'
    }
},];

const extraEquipmentMeasurementTypes = [{
    title: 'equipment_attached',
    id: 'equipment_attached',
    getMeasurement: function (measurement, pole_base) {
        return measurementTools.getHeight(measurement, pole_base) + 'ft '
    }
},
{
    title: 'equipment_attached_proposed',
    id: 'equipment_attached_proposed',
    getMeasurement: function (measurement, pole_base) {
        return measurementTools.getHeight(measurement, pole_base) + 'ft'
    }
},
{
    title: 'equipment_bottom',
    id: 'equipment_bottom',
    getMeasurement: function (measurement, pole_base) {
        return measurementTools.getHeight(measurement, pole_base) + 'ft '
    }
},
{
    title: 'equipment_bottom_proposed',
    id: 'equipment_bottom_proposed',
    getMeasurement: function (measurement, pole_base) {
        return measurementTools.getHeight(measurement, pole_base) + 'ft'
    }
}];

const transmissionMeasurementTypes = [{
    title: 'existing',
    id: 'transmission_existing',
    getMeasurement: function (measurement, pole_base) {
        return measurementTools.getHeight(measurement, pole_base) + 'ft '
    }
},
{
    title: 'proposed',
    id: 'transmission_proposed',
    getMeasurement: function (measurement, pole_base) {
        return measurementTools.getHeight(measurement, pole_base) + 'ft'
    }
},];

const wirelineMeasurementTypes = [{
    title: 'existing',
    id: 'wireline_existing',
    getMeasurement: function (measurement, pole_base) {
        return measurementTools.getHeight(measurement, pole_base) + 'ft '
    }
},
{
    title: 'proposed',
    id: 'wireline_proposed',
    getMeasurement: function (measurement, pole_base) {
        return measurementTools.getHeight(measurement, pole_base) + 'ft'
    }
},];

const wirelessMeasurementTypes = [
    {
        title: 'antenna_top',
        id: 'antenna_top',
        getMeasurement: function (measurement, pole_base) {
            return measurementTools.getHeight(measurement, pole_base) + 'ft '
        }
    },
    {
        title: 'antenna_attached',
        id: 'antenna_attached',
        getMeasurement: function (measurement, pole_base) {
            return measurementTools.getHeight(measurement, pole_base) + 'ft '
        }
    },
    {
        title: 'antenna_bottom',
        id: 'antenna_bottom',
        getMeasurement: function (measurement, pole_base) {
            return measurementTools.getHeight(measurement, pole_base) + 'ft '
        }
    },
    {
        title: 'antenna_top_proposed',
        id: 'antenna_top_proposed',
        getMeasurement: function (measurement, pole_base) {
            return measurementTools.getHeight(measurement, pole_base) + 'ft '
        }
    },
    {
        title: 'antenna_attached_proposed',
        id: 'antenna_attached_proposed',
        getMeasurement: function (measurement, pole_base) {
            return measurementTools.getHeight(measurement, pole_base) + 'ft '
        }
    },
    {
        title: 'antenna_bottom_proposed',
        id: 'antenna_bottom_proposed',
        getMeasurement: function (measurement, pole_base) {
            return measurementTools.getHeight(measurement, pole_base) + 'ft '
        }
    }
];


export {
    distributionMeasurementTypes,
    extraEquipmentMeasurementTypes,
    transmissionMeasurementTypes,
    wirelineMeasurementTypes,
    wirelessMeasurementTypes
}