
import { createContext, useState } from 'react'

export const MapLayerContext = createContext(null)

export default function MapProvider({ children }) {
    const [workareas, setWorkareas] = useState();

    const position = {
        workareas: [workareas, setWorkareas],
    }

    return <MapLayerContext.Provider value={position}>{children}</MapLayerContext.Provider>
}