import React, { useState } from 'react';
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const CustomTreeDataGrid = ({ parentAssetTypeId, gridRef, ...props }) => {
  const [expandedRows, setExpandedRows] = useState(
    props.rows.filter((row) => row.asset_type_id === parentAssetTypeId)
      .map((row) => row.source_sys_id || row.id)
  );

  const handleExpandClick = (params) => {
    if (expandedRows.includes(params.row.source_sys_id || params.row.id)) {
      setExpandedRows(expandedRows.filter((id) => ![params.row.source_sys_id, params.row.id].includes(id)));
    } else {
      setExpandedRows([...expandedRows, params.row.source_sys_id || params.row.id]);
    }
  };

  const isRowVisible = (row) => {
    return row.asset_type_id === parentAssetTypeId || expandedRows.find(expanded => row.path.includes(expanded));
  };

  const visibleRows = props.rows.filter(isRowVisible);

  // Sort rows such that children are always nested below their parent
  const sortedRows = visibleRows.sort((a, b) => {
    const aPath = a.path.join('/');
    const bPath = b.path.join('/');
    return aPath.localeCompare(bPath);
  });

  const columns = [
    {
      field: 'expand',
      type: 'actions',
      maxWidth: 20,
      getActions: (params) => {
        let items = [];
        if (params.row.asset_type_id === parentAssetTypeId) {
          const isExpanded = expandedRows.includes(params.row.source_sys_id || params.row.id);
          items.push(
            <GridActionsCellItem
              icon={isExpanded ? <ExpandMoreIcon /> : <ChevronRightIcon />}
              label=""
              onClick={() => handleExpandClick(params)}
            />
          );
        }
        return items;
      },
    },
    ...props.columns
  ]

  return (
    <DataGrid
      {...props}
      apiRef={props.apiRef}
      rows={sortedRows}
      columns={columns}
    />
  );
};

export default CustomTreeDataGrid;
