import React, { useRef, useEffect, useContext, useState } from "react";
import { PanoramaApi } from '../../api';
import { StoreContext } from '../../Contexts';
import { ReactPhotoSphereViewer, CompassPlugin } from 'react-photo-sphere-viewer';

const plugins = [[CompassPlugin]]
function PhotoSphereViewer() {
    const appContext = useContext(StoreContext);
    const [imageId] = appContext.imageId;
    const [imageUrl, setImageUrl] = useState();
    const [imageData, setImageData] = useState();
    const photoSphereRef = useRef();

    useEffect(() => {
        const getNewImageData = async () => {
            let newImageData = await PanoramaApi.fetchPanoImageData(imageId)
            setImageData(newImageData);
            setImageUrl(await PanoramaApi
                .fetchPanoImage(newImageData.Frame_folder,
                    newImageData.Frame_filename,
                    newImageData.Frame_bucket_name));
        }
        if (imageId) { getNewImageData() }
    }, [imageId]);

    useEffect(() => {
        const updateImage = () => {
            const headingDeg = Number(imageData?.Heading_deg ? imageData.Heading_deg : 0);
            const heading360 = headingDeg > 0 ? headingDeg : 360 + headingDeg;

            photoSphereRef.current?.setPanorama(imageUrl, {
                caption: `Collection Id: ${imageData.collection_id} ` +
                    `Collection Date: ${imageData.created_date} ` +
                    `Heading: ${Number.parseFloat(imageData.Heading_deg || 0).toFixed(1)} ` +
                    `Latitude: ${Number.parseFloat(imageData.Latitude_deg || 0).toFixed(6)} ` +
                    `Longitude: ${Number.parseFloat(imageData.Longitude_deg || 0).toFixed(6)}`,
                panoData: {
                    poseHeading: heading360, // 0 to 360
                },
                position: { yaw: (heading360 / 360) * 2 * Math.PI, pitch: 0 }
            });
        }
        if (imageUrl) { updateImage() }
    }, [imageUrl, imageData])

    return (
        <div className="pano-wrap">
            {imageUrl &&
                <ReactPhotoSphereViewer
                    ref={photoSphereRef}
                    minFov={5}
                    navbar={[
                        'fullscreen',
                        'caption',
                        'zoom',
                        'move'
                    ]}
                    height={'100%'}
                    width={"100%"}
                    plugins={plugins}>
                </ReactPhotoSphereViewer>
            }
        </div >
    );
};

export default PhotoSphereViewer;