/* eslint-disable */
import React, { useEffect, useState, useContext } from "react";
import { StoreContext } from '../../Contexts';
import {
    Button,
    Alert,
    Card,
    CardContent,
    Tabs,
    Tab,
    Stack,
} from '@mui/material';
import { measurementTools, locationFunctions } from "../../utils/reusable-functions";
import CustomTabPanel from "../../custom-components/CustomTabPanel";
import { InteractiveSection, StepOption } from "../../Enums";
import LoadingButton from '@mui/lab/LoadingButton';
import { assetService } from "../../services";
import AssetOptionsComponent from "../../custom-components/AssetOptionsComponent";
import AssetForm from "../asset-form-panel/AssetForm";
import AssetInputGrid from "./AssetInputGrid";
import AnchorInputGrid from "./AnchorInputGrid";


const AssetFormPanel = () => {
    const appContext = useContext(StoreContext);
    const [, setSnackbar] = appContext.snackbar;
    const [potreeViewer] = appContext.potreeViewer;
    const [activePole, setActivePole] = appContext.activePole;
    const [activePointcloud] = appContext.activePointcloud;
    const [imageId] = appContext.imageId;
    const [measurementMap, setMeasurementMap] = appContext.measurementMap;
    const [potreeMode, setPotreeMode] = appContext.potreeMode;
    const [markerPlacement, setMarkerPlacement] = appContext.markerPlacement;
    const [sideBarModules, setSidebarModules] = appContext.sideBarModules;
    const [loading, setLoading] = appContext.loading;
    const [validationErrors, setValidationErrors] = appContext.validationErrors;
    const [, setPolesUpdateTrigger] = appContext.polesUpdateTrigger;
    const [activeMeasurementTab, setActiveMeasurementTab] = useState(0);
    const [step, setStep] = useState(StepOption.Create);

    const getCurrentCameraPosition = () => {
        return potreeViewer.scene.getActiveCamera().position;
    }

    const tabList = [
        {
            id: 'pole',
            label: 'Pole'
        },
        {
            id: 'attachments',
            label: 'Attachments'
        },
        {
            id: 'anchors',
            label: 'Anchors'
        },
        {
            id: 'wokflow_status',
            label: 'Workflow Status'
        }
    ]

    const tabChange = (e, newValue) => {
        setActiveMeasurementTab(newValue);
    }

    const createNewPole = async () => {
        let [long, lat] = locationFunctions.getLatLngOfCoordinate(markerPlacement[0], activePointcloud.crs);
        const cameraPosition = getCurrentCameraPosition();
        let newPole = {};
        newPole.x_longitude = long.toFixed(12);
        newPole.y_latitude = lat.toFixed(12);
        newPole.z_altitude = parseInt(measurementTools.getElevation(markerPlacement[0], true).split(' ')[0]);
        newPole.asset_type_id = 1;
        newPole.organization_service_area_id = 1;
        newPole.asset_name = "POLE";
        newPole.asset_properties = {
            point_cloud_coordinates: {
                x: markerPlacement[0].points[0].position.x,
                y: markerPlacement[0].points[0].position.y,
                z: markerPlacement[0].points[0].position.z
            },
            pointcloud_scene_data: { cam: cameraPosition },
            pointcloud_def: activePointcloud,
            image_id: imageId,
        };
        setLoading(true);
        const selectedPole = await assetService.saveAsset(newPole, true);
        setActivePole(selectedPole);
        setLoading(false);
        setStep(StepOption.Update);
    }

    const setPointMode = (measurementLabel) => {
        // only start insertion if the measurement has not yet been taken
        if (!Object.keys(measurementMap).includes(measurementLabel)) {
            setPotreeMode(true);
            const measurment = potreeViewer.measuringTool.startInsertion({
                showDistances: false,
                showAngles: false,
                showCoordinates: false,
                showArea: false,
                closed: true,
                maxMarkers: 1,
                name: 'Point'
            });

            //here you will define all behaviors for when markers change. 
            measurment.addEventListener('marker_dropped', (event) => {
                setMarkerPlacement([...potreeViewer.scene.measurements]);
                // map the point object to the ui. 
                setMeasurementMap(prevMap => ({
                    ...prevMap, // Copy existing items
                    [measurementLabel]: event.target, // Add/update the new item
                }));
                locationFunctions.getLatLngOfCoordinate(event.target, activePointcloud.crs);
            });
        }
    }

    const a11yProps = (index) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const openGroundCollect = () => {
        setSidebarModules({
            ...sideBarModules,
            right_top: InteractiveSection.RequestGroundCollection,
        });
    }

    const assetsUpdated = async () => {
        setActivePole(await assetService.fetchAsset(activePole.asset_id || activePole.asset_uuid));
        setPolesUpdateTrigger(prev => !prev);
    }

    useEffect(() => {
        if (activePole) {
            setStep(StepOption.Update);
        } else {
            setStep(StepOption.Create);
            setActiveMeasurementTab(0);
        }
    }, [activePole])

    useEffect(() => {
        if (validationErrors.length > 0) {
            setSnackbar({
                open: true,
                message: `missing data: ${JSON.stringify(validationErrors)}`,
                severity: 'error'
            })
            setValidationErrors([]);
        }
    }, [validationErrors])

    if (potreeViewer !== null) {
        // the interface for creating a pole. 
        if (potreeMode && step === StepOption.Create) {
            return (
                <div className="pole-management-wrap">
                    <Alert severity="info">Click the base of the pole in the point cloud to set pole location.</Alert>
                    <br />
                    {markerPlacement.map((marker, index) => (
                        <Card key={index} sx={{ minWidth: 275 }}>
                            <CardContent>
                                <div key={index}>
                                    <p>Elevation: {measurementTools.metersToFeet(marker.points[0].position.z)}ft</p>
                                    <p>Lat: {locationFunctions.getLatLngOfCoordinate(marker, activePointcloud.crs)[1]}</p>
                                    <p>Lng: {locationFunctions.getLatLngOfCoordinate(marker, activePointcloud.crs)[0]}<br /></p>
                                </div>
                                <LoadingButton size="small" variant="outlined" onClick={createNewPole} loading={loading}>
                                    Update
                                </LoadingButton>
                                <br />
                            </CardContent>
                        </Card>
                    ))}
                </div>
            )
        }

        // the interface for updating a pole.       
        if (activePole && step === StepOption.Update) {
            return (
                <div className="pole-management-wrap">
                    {activePole ? (
                        <Tabs
                            value={activeMeasurementTab}
                            onChange={tabChange}
                            variant="fullWidth"
                            aria-label="measurement tabs"
                            size='small'>
                            {tabList.map((tab, index) => (
                                <Tab key={index} label={tab.label} {...a11yProps(index)} />
                            ))}
                        </Tabs>
                    ) : (
                        <Alert severity="info">
                            Before Adding attachment measurements you must save.
                        </Alert>
                    )}
                    <CustomTabPanel value={activeMeasurementTab} index={0}>
                        <AssetForm
                            asset={activePole}
                            updateAsset={setActivePole} />
                    </CustomTabPanel>
                    <CustomTabPanel value={activeMeasurementTab} index={1}>
                        <AssetInputGrid />
                    </CustomTabPanel>
                    <CustomTabPanel value={activeMeasurementTab} index={2}>
                        <AnchorInputGrid />
                    </CustomTabPanel>
                    <CustomTabPanel value={activeMeasurementTab} index={3}>
                        <AssetOptionsComponent
                            assetIds={[activePole.asset_uuid]}
                            assetsUpdated={assetsUpdated}
                            assignee={activePole.assignee}
                            workflow_status_id={activePole.workflow_status_id}
                            saving={loading}
                            setSaving={setLoading} />
                    </CustomTabPanel>
                </div>
            )
        }

        if (!potreeMode && step !== StepOption) {
            return (
                <Stack spacing={2} sx={{ p: 2 }}>
                    <Button size="small" variant="outlined" onClick={() => { setPointMode('pointcloud_coordinates') }}>Set Pole Base</Button>
                    <Button size="small"
                        variant="outlined"
                        onClick={openGroundCollect}>
                        Request Ground Collect
                    </Button>
                </Stack>
            )
        }
    } else {
        return (
            <></>
        );
    }
};

export default AssetFormPanel;