import { StoreContext } from '../../Contexts';
import React, { useContext, useState, useEffect } from "react";
import {
    Backdrop,
    CircularProgress,
    Container,
    Box
} from '@mui/material';
import AddPoleButton from '../../custom-components/AddPoleButton';
import DeletePoleButton from '../../custom-components/DeletePoleButton';
import AssetInfoChip from '../../custom-components/AssetInfoChip';
import SavePoleButton from '../../custom-components/SavePoleButton';

const AssetFormHeader = () => {
    const appContext = useContext(StoreContext);
    const [activePole] = appContext.activePole;
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (activePole) {
            setLoading(false);
        } else {
            setLoading(true);
        }
    }, [activePole]);

    if (activePole) {
        return (
            <Container sx={{
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
                p: 0
            }}>
                <Box sx={{ position: 'absolute', right: 0, top: 0, display: 'flex' }}>
                    <SavePoleButton />
                    <DeletePoleButton loading={loading} />
                    <AddPoleButton />
                </Box>
                <AssetInfoChip />
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={loading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
            </Container >)
    }
}

export default AssetFormHeader;