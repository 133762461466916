/* eslint-disable */

// will load on map-one route. 
import React, { useContext, useEffect } from "react";
import { StoreContext } from '../Contexts';
import { InteractiveSection, LayoutOption } from "../Enums";
import { InteractiveSectionSelector } from "../utils";


const DumyModules = () => {
  const appContext = useContext(StoreContext);
  const [, setLayout] = appContext.layout;
  const [, setSidebarModules] = appContext.sideBarModules;
  const [mainView, setMainView] = appContext.mainView;

  useEffect(() => {
    setLayout(LayoutOption.SideBars);
    setSidebarModules({
      left_top: InteractiveSection.Dummy, // top sidebar left (toolbar 1)
      left_bottom: InteractiveSection.Dummy, //bottom sidebar left (toolbar 1)
      right_top: InteractiveSection.Dummy, // bottom sidebar right (toolbar 2)
      right_bottom: InteractiveSection.Dummy,
    });
    setMainView(InteractiveSection.Dummy);
  }, []);

  return (
    <InteractiveSectionSelector section={mainView} />
  );
}

export default DumyModules;
