import { PoleApi, TransmissionApi, DistributionApi, CommsApi } from "../api";
import { measurementTools } from "./reusable-functions";
import vec3ToSerializedString from "./reusable-functions/vec3ToSerializedString";

async function getPolesInArea(collection_name, bounds) {
    const polesInArea = await PoleApi.fetchPolesByBounds(
        collection_name,
        bounds.northEast.latitude,
        bounds.northEast.longitude,
        bounds.southWest.latitude,
        bounds.southWest.longitude
    );

    return polesInArea.length < 200 ? polesInArea : [];
}

const updateMeasurementIfValueHasChanged = async (measurementMap, key, activePole) => {
    const currentUser = localStorage.getItem('currentUser');
    const potreeCoords = measurementMap[key];
    const potreeCoordAsHeight = measurementTools.getHeight(measurementMap[key], measurementMap['pole_base']);
    const keySplit = key.split('_');
    const recordId = keySplit[keySplit.length - 1];

    if (key.includes('transmission')) {
        const theCurrentRecord = activePole.transmission_lines.find((attachment) => attachment.id === recordId);
        let updatedTransmission = {};

        if (key.includes('existing') && theCurrentRecord?.existing !== potreeCoordAsHeight) {
            updatedTransmission.existing = potreeCoordAsHeight;
            updatedTransmission.existing_coord = vec3ToSerializedString(potreeCoords.points[0].position);
        }

        if (key.includes('proposed') && theCurrentRecord?.proposed !== potreeCoordAsHeight) {
            updatedTransmission.proposed = potreeCoordAsHeight;
            updatedTransmission.proposed_coord = vec3ToSerializedString(potreeCoords.points[0].position);
        }
        if (Object.keys(updatedTransmission).length > 0) {
            updatedTransmission.id = theCurrentRecord.id;
            updatedTransmission.reviewer = currentUser.username;
            await TransmissionApi.upsertTransmission(updatedTransmission)
        }
    }

    if (key.includes('distribution')) {
        const theCurrentRecord = activePole.distribution_lines.find((attachment) => attachment.id === recordId);
        let updatedDistribution = {};

        if (key.includes('existing') && theCurrentRecord?.existing !== potreeCoordAsHeight) {
            updatedDistribution.existing = potreeCoordAsHeight;
            updatedDistribution.existing_coord = vec3ToSerializedString(potreeCoords.points[0].position);
        }

        if (key.includes('proposed') && theCurrentRecord?.proposed !== potreeCoordAsHeight) {
            updatedDistribution.proposed = potreeCoordAsHeight;
            updatedDistribution.proposed_coord = vec3ToSerializedString(potreeCoords.points[0].position);
        }
        if (Object.keys(updatedDistribution).length > 0) {
            updatedDistribution.id = theCurrentRecord.id;
            updatedDistribution.reviewer = currentUser.username;
            await DistributionApi.upsertDistribution(updatedDistribution);
        }
    }

    if (key.includes('equipment')) {
        const theCurrentRecord = activePole.distribution_equipment.find((attachment) => attachment.id === recordId);
        let updatedEquipment = {};

        // if the length of the split is 3 then it is existing.
        // if the length of the split is 4 then it is proposed. 
        if (keySplit.length === 3
            && keySplit.slice(0, 2).join("_") === 'equipment_attached'
            && theCurrentRecord?.equipment_attached !== potreeCoordAsHeight) {
            updatedEquipment.equipment_attached = potreeCoordAsHeight;
            updatedEquipment.equipment_attached_coord = vec3ToSerializedString(potreeCoords.points[0].position);
        }

        if (keySplit.length === 3
            && keySplit.slice(0, 2).join("_") === 'equipment_bottom'
            && theCurrentRecord?.equipment_bottom !== potreeCoordAsHeight) {
            updatedEquipment.equipment_bottom = potreeCoordAsHeight;
            updatedEquipment.equipment_bottom_coord = vec3ToSerializedString(potreeCoords.points[0].position);
        }

        if (keySplit.length === 4
            && keySplit.slice(0, 3).join("_") === 'equipment_attached_proposed'
            && theCurrentRecord?.equipment_attached_proposed !== potreeCoordAsHeight) {
            updatedEquipment.equipment_attached_proposed = potreeCoordAsHeight;
            updatedEquipment.equipment_attached_proposed_coord = vec3ToSerializedString(potreeCoords.points[0].position);
        }

        if (keySplit.length === 4
            && keySplit.slice(0, 3).join("_") === 'equipment_bottom_proposed'
            && theCurrentRecord?.equipment_bottom_proposed !== potreeCoordAsHeight) {
            updatedEquipment.equipment_bottom_proposed = potreeCoordAsHeight;
            updatedEquipment.equipment_bottom_proposed_coord = vec3ToSerializedString(potreeCoords.points[0].position);
        }
        if (Object.keys(updatedEquipment).length > 0) {
            updatedEquipment.id = theCurrentRecord?.id;
            updatedEquipment.reviewer = currentUser.username;
            await DistributionApi.upsertExtraDistributionEquipment(updatedEquipment)
        }
    }

    if (key.includes('wireline')) {
        const theCurrentRecord = activePole.wireline_comms.find((attachment) => attachment.id === recordId);
        let updatedWirelineComm = {};

        if (key.includes('proposed') && theCurrentRecord?.proposed !== potreeCoordAsHeight) {
            updatedWirelineComm.proposed = potreeCoordAsHeight;
            updatedWirelineComm.proposed_coord = vec3ToSerializedString(potreeCoords.points[0].position);
        }

        if (key.includes('existing') && theCurrentRecord?.existing !== potreeCoordAsHeight) {
            updatedWirelineComm.existing = potreeCoordAsHeight;
            updatedWirelineComm.existing_coord = vec3ToSerializedString(potreeCoords.points[0].position);
        }
        if (Object.keys(updatedWirelineComm).length > 0) {
            updatedWirelineComm.id = theCurrentRecord.id;
            updatedWirelineComm.reviewer = currentUser.username;
            await CommsApi.upsertWirelineComm(updatedWirelineComm);
        }
    }

    if (key.includes('antenna')) {
        const theCurrentRecord = activePole.wireless_comms.find((attachment) => attachment.id === recordId);
        let updatedWirelessComm = {};

        if (key.includes('proposed')
            && key.includes('attached')
            && theCurrentRecord?.antenna_attached_proposed !== potreeCoordAsHeight) {
            updatedWirelessComm.antenna_attached_proposed = potreeCoordAsHeight;
            updatedWirelessComm.antenna_attached_proposed_coord = vec3ToSerializedString(potreeCoords.points[0].position);
        }

        if (key.includes('proposed')
            && key.includes('top')
            && theCurrentRecord?.antenna_top_proposed !== potreeCoordAsHeight) {
            updatedWirelessComm.antenna_top_proposed = potreeCoordAsHeight;
            updatedWirelessComm.antenna_top_proposed_coord = vec3ToSerializedString(potreeCoords.points[0].position);
        }

        if (key.includes('proposed')
            && key.includes('bottom')
            && theCurrentRecord?.antenna_bottom_proposed !== potreeCoordAsHeight) {
            updatedWirelessComm.antenna_bottom_proposed = potreeCoordAsHeight;
            updatedWirelessComm.antenna_bottom_proposed_coord = vec3ToSerializedString(potreeCoords.points[0].position);
        }

        if (keySplit.length === 3
            && keySplit.slice(0, 2).join("_") === 'antenna_top'
            && theCurrentRecord?.antenna_top !== potreeCoordAsHeight) {
            updatedWirelessComm.antenna_top = potreeCoordAsHeight;
            updatedWirelessComm.antenna_top_coord = vec3ToSerializedString(potreeCoords.points[0].position);
        }

        if (keySplit.length === 3
            && keySplit.slice(0, 2).join("_") === 'antenna_bottom'
            && theCurrentRecord?.antenna_bottom !== potreeCoordAsHeight) {
            updatedWirelessComm.antenna_bottom = potreeCoordAsHeight;
            updatedWirelessComm.antenna_bottom_coord = vec3ToSerializedString(potreeCoords.points[0].position);
        }

        if (keySplit.length === 3
            && keySplit.slice(0, 2).join("_") === 'antenna_attached'
            && theCurrentRecord?.antenna_attached !== potreeCoordAsHeight) {
            updatedWirelessComm.antenna_attached = potreeCoordAsHeight;
            updatedWirelessComm.antenna_attached_coord = vec3ToSerializedString(potreeCoords.points[0].position);
        }
        if (Object.keys(updatedWirelessComm).length > 0) {
            updatedWirelessComm.id = theCurrentRecord.id;
            updatedWirelessComm.reviewer = currentUser.username;
            await CommsApi.upsertWirelessComm(updatedWirelessComm);
        }
    }
    return true;
}

const updateAttachmentsIfNeeded = async (measurementMap, activePole) => {
    const updatePromises = Object.keys(measurementMap).map(measurementKey => {
        // Map each measurement key to an update operation, starting all operations in parallel
        return updateMeasurementIfValueHasChanged(measurementMap, measurementKey, activePole);
    });
    // Wait for all update operations to complete
    await Promise.all(updatePromises);
};

const updateExistingPole = async (poleFormData, activePole, measurementMap) => {
    let updateObject = { ...poleFormData }
    updateObject.id = activePole.id;
    updateObject.updated_by = JSON.parse(localStorage.getItem('currentUser')).username;
    await PoleApi.updatePole(updateObject);
    await updateAttachmentsIfNeeded(measurementMap, activePole);
};

export {
    getPolesInArea,
    updateExistingPole
}