/* eslint-disable */
import { StoreContext } from '../../Contexts';
import React, { useContext, useEffect, useState } from "react";
import { PoleApi } from '../../api';
import { DropdownValueType } from '../../Enums';
import {
  LinearProgress,
  Container,
} from '@mui/material';
import DetailsDisplaySection from '../../custom-components/DetailsDisplaySection';

const poleDisplayFields = [
  'assignee',
  'date_updated',
  'pole_owner',
  'pole_tag_1',
  'pole_tag_2',
  'pole_tag_3',
  'lat',
  'lng',
  'pole_field_notes',
  'pole_base',
  'pole_length',
  'pole_agl'];

const transmissionDisplayFields = [
  'existing',
  'proposed'];
const distributionDisplayFields = [
  'existing',
  'proposed',
  'distribution_type_id'];
const distributionEquipmentDisplayFields = [
  'equipment_attached',
  'equipment_bottom',
  'equipment_attached_proposed',
  'equipment_bottom_proposed',
  'equipment_type_id'];
const wirelessDisplayFields = [
  'antenna_top',
  'antenna_attached',
  'antenna_bottom',
  'antenna_top_proposed',
  'antenna_attached_proposed',
  'antenna_bottom_proposed']
const wirelineDisplayFields = [
  'existing',
  'proposed',
  'comms_type_id'];
const anchorDisplayFields = [
  'azimuth',
  'azimuth_proposed',
  'lead_span_length',
  'lead_span_length_proposed',
  'anchor_rod_diameter_id'];

function PoleInfoPanel() {
  const appContext = useContext(StoreContext);
  const [activePole] = appContext.activePole;
  const [dropdownData, setDropdownData] = appContext.dropdownData;
  const [loading, setLoading] = useState(true);

  const getDropdownData = async () => {
    if (dropdownData === null) {
      const response = await PoleApi.fetchPoleDropdownValues();
      setDropdownData(response);
    }
  }

  useEffect(() => {
    getDropdownData();
  }, []);

  useEffect(() => {
    if (activePole && Object.keys(activePole).length) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [activePole]);

  if (!(activePole && Object.keys(activePole).length && dropdownData)) {
    return (
      <div className="active-pole-info-panel">
        {loading && <LinearProgress />}
      </div>
    );
  } else {
    return (
      <Container className="active-pole-info-panel">
        <DetailsDisplaySection object={activePole}
          displayFields={poleDisplayFields} />
        <DetailsDisplaySection object={activePole.transmission_lines}
          displayFields={transmissionDisplayFields}
          title={'Transmission'} />
        <DetailsDisplaySection object={activePole.distribution_lines}
          displayFields={distributionDisplayFields}
          title={'Distribution'}
          dropdownDataObj={dropdownData.pole_distribution}
          dropdownDataKey={'label'} />
        <DetailsDisplaySection object={activePole.distribution_equipment}
          displayFields={distributionEquipmentDisplayFields}
          title={'Distribution Equipment'}
          dropdownDataObj={dropdownData.pole_distribution_equip_typ}
          dropdownDataKey={'type'} />
        <DetailsDisplaySection object={activePole.wireless_comms}
          displayFields={wirelessDisplayFields}
          title={'Wireless'} />
        <DetailsDisplaySection object={activePole.wireline_comms}
          displayFields={wirelineDisplayFields}
          title={'Wireline'}
          dropdownDataObj={dropdownData.pole_attachment_type}
          dropdownDataKey={'attachment_type'} />
        <DetailsDisplaySection object={activePole.anchors}
          displayFields={anchorDisplayFields}
          title={'Guy Anchors'}
          dropdownDataObj={dropdownData[DropdownValueType.GuyAnchorDiameter.type]}
          dropdownDataKey={[DropdownValueType.GuyAnchorDiameter.labelKey]} />
      </Container>
    );
  }
}

export default PoleInfoPanel;