import { useContext, useEffect } from "react";
import { PositionContext, StoreContext } from "../../../Contexts";
import { assetService, mapService } from "../../../services";
import { PointCloudApi } from "../../../api";
import { openPointCloud } from "../../../utils/PointCloudUtils";
import { InteractiveSection, LayoutOption } from "../../../Enums";
import { useNavigate } from "react-router-dom";
import { MapUtils } from "../../../utils";

const PoleLayer = (
    { map,
        visible,
        layerId,
        layerRef,
        updateLayer,
        poles }, ...props) => {
    const { Maps } = window.Microsoft;
    const navigate = useNavigate();
    const appContext = useContext(StoreContext);
    const positionContext = useContext(PositionContext);
    const [mainView] = appContext.mainView;
    const [, setLayout] = appContext.layout;
    const [, setSidebarModules] = appContext.sideBarModules;
    const [activeProject] = appContext.activeProject;
    const [, setActivePole] = appContext.activePole;
    const [workflowStatuses] = appContext.workflowStatuses;
    const [, setActivePointCloud] = appContext.activePointcloud;
    const [, setLatitude] = positionContext.latitude;
    const [, setLongitude] = positionContext.longitude;
    const [, setLoading] = appContext.loading;

    const updatePolesInArea = async (mapRef) => {
        updateLayer(layerId, null);
        let layer = mapService.initLayer(layerId, mapRef, visible);
        layer.clear();

        poles.forEach((pole) => {
            let pushpin = new Maps.Pushpin({
                latitude: pole.y_latitude ?? pole.lat,
                longitude: pole.x_longitude ?? pole.lng
            }, {
                icon: MapUtils.getMapIcon(layerId, pole.workflow_status_color, true),
                // title: `${pole.asset_uuid || pole.id}`,
            });
            pushpin.customData = pole;
            pushpin.id = pole.asset_uuid || pole.id;
            layer.add(pushpin);

            Maps.Events.addHandler(pushpin, 'click', async (e) => {
                setLoading(true);
                setActivePole(null);
                let selectedPole = await assetService.fetchAsset(pushpin.customData.asset_uuid ?? pushpin.customData.id);
                setActivePole(selectedPole);
                setLatitude(e.location.latitude);
                setLongitude(e.location.longitude);
                if (mainView === InteractiveSection.Map) {
                    setLayout(LayoutOption.SideBarRight);
                    setSidebarModules(prevState => ({
                        ...prevState,
                        right_header: InteractiveSection.AssetInfoHeader,
                        right_top: InteractiveSection.AssetInfoPanel
                    }));
                }
                else {
                    const selectedPointCloudData = await PointCloudApi
                        .fetchPointCloudByGPSLocation(activeProject.collection_name, e.location.latitude, e.location.longitude);
                    await openPointCloud(setActivePointCloud,
                        mainView,
                        navigate,
                        selectedPointCloudData[0],
                        e.location.latitude,
                        e.location.longitude,
                        pushpin.customData.asset_uuid ?? pushpin.customData.id);
                }
                mapRef.setView({
                    center: new Maps.Location(e.location.latitude, e.location.longitude)
                })
                setLoading(false);
            });
        });
        updateLayer(layerId, layer);
    }

    useEffect(() => {
        const plotPolesInArea = async () => {
            Maps.Events.addOne(map, 'viewrendered', async () => {
                await updatePolesInArea(map);
            });
        }
        if (map && workflowStatuses) {
            plotPolesInArea();
        }
    }, [map, workflowStatuses])

    useEffect(() => {
        if (layerRef) {
            layerRef.setVisible(visible);
        }
    }, [visible, layerRef])

    useEffect(() => {
        if (map && workflowStatuses) {
            updatePolesInArea(map);
        }
    }, [poles])
}

export default PoleLayer;