import React, { useState, useContext } from "react";
import {
    IconButton,
    ButtonGroup,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText,
    Tooltip
} from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { StoreContext } from "../Contexts";
import { LoadingButton } from "@mui/lab";
import { assetService } from "../services";
import { AssetApi } from "../api";

const DeleteAssetButton = ({
    asset,
    onDeleteAction = () => { } }) => {
    const appContext = useContext(StoreContext);
    const [potreeViewer] = appContext.potreeViewer;
    const [relatedAssets, setRelatedAssets] = appContext.relatedAssets;
    const [loading] = appContext.loading;
    const [deleting, setDeleting] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);

    const deleteAsset = async () => {
        setDeleting(true);
        await assetService.deleteAsset(asset);
        let attachedAssets = await AssetApi.getAttachedAssets([asset.source_sys_id], asset.input_source_type_id);
        setDeleting(false);
        removeMeasurements(attachedAssets);
        removeAttachedAsset(asset);
        setModalOpen(false);
        onDeleteAction(asset.asset_uuid);
    }

    const removeMeasurements = (attachedAssets) => {
        let measurements = potreeViewer?.scene.measurements.filter(m => m.name.includes(asset.asset_uuid));
        measurements.forEach(m => potreeViewer.scene.removeMeasurement(m));

        attachedAssets?.forEach(attached => {
            measurements = potreeViewer?.scene.measurements.filter(m => m.name.includes(attached.asset_uuid));
            measurements.forEach(m => potreeViewer.scene.removeMeasurement(m));
        })
    }

    const removeAttachedAsset = (attachedAsset) => {
        let attachedAssetIndex = relatedAssets.findIndex(a => a.asset_uuid === attachedAsset.asset_uuid ?? a.id === attachedAsset.id);
        if (attachedAssetIndex > -1) {
            relatedAssets.splice(attachedAssetIndex, 1);
            setRelatedAssets([...relatedAssets]);
        }
    }

    const openModal = () => {
        setModalOpen(true);
    }

    const closeModal = () => {
        setModalOpen(false);
    }

    return (
        asset.input_source_type_id === 3 && <>
            <Tooltip title="Delete Asset" arrow>
                <IconButton size="small" color="error" onClick={openModal} sx={{ float: "right" }}>
                    <DeleteForeverIcon />
                </IconButton>
            </Tooltip>
            <Dialog
                open={modalOpen}
                keepMounted
                onClose={closeModal}
                aria-describedby="alert-dialog-slide-description">
                <DialogTitle>{`Delete Asset #${asset.asset_uuid}`}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Are you sure you want to delete this asset? All associated mesurements will also be deleted.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <ButtonGroup>
                        <Button variant="outlined" disabled={loading} color='primary' onClick={closeModal}>Cancel</Button>
                        <LoadingButton variant="outlined" loading={deleting} color="error" onClick={deleteAsset}>Delete</LoadingButton>
                    </ButtonGroup>
                </DialogActions>
            </Dialog>

        </>
    )
}

export default DeleteAssetButton;