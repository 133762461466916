/* eslint-disable */
import { StoreContext } from '../../Contexts';
import React, { useContext, useEffect, useState } from "react";
import { PoleApi } from '../../api';
import { DropdownValueType } from '../../Enums';
import {
    LinearProgress,
    Container,
} from '@mui/material';
import DetailsDisplaySection from '../../custom-components/DetailsDisplaySection';
import AssetDetailsDisplaySection from '../../custom-components/AssetDetailsDisplaySection';

function AssetInfoPanel() {
    const appContext = useContext(StoreContext);
    const [activePole] = appContext.activePole;
    const [dropdownData, setDropdownData] = appContext.dropdownData;
    const [loading, setLoading] = appContext.loading;

    const getDropdownData = async () => {
        if (dropdownData === null) {
            const response = await PoleApi.fetchPoleDropdownValues();
            setDropdownData(response);
        }
    }

    useEffect(() => {
        getDropdownData();
    }, []);

    useEffect(() => {
        if (activePole && Object.keys(activePole).length) {
            setLoading(false);
        } else {
            setLoading(true);
        }
    }, [activePole]);

    if (!(activePole && Object.keys(activePole).length && dropdownData)) {
        return (
            <div className="active-pole-info-panel">
                {loading && <LinearProgress />}
            </div>
        );
    } else {
        return (
            <Container className="active-pole-info-panel">
                <AssetDetailsDisplaySection asset={activePole.asset_properties} title={activePole.asset_name} />
            </Container>
        );
    }
}

export default AssetInfoPanel;