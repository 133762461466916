import { WorkareaApi, AssetWorkflowApi } from "../api"

const addWorkareaWithAssets = async (workarea, assetIds, selectedOptions) => {
    let currentUsername = JSON.parse(localStorage.getItem('currentUser')).username;
    workarea.created_by = currentUsername;
    if (!workarea.workarea_id) {
        try {
            workarea.workarea_id = (await WorkareaApi.addWorkarea(workarea)).workarea_id;
        } catch (e) {
            console.log(e);
            throw Error(`Failed to add workarea`);
        }
    }
    await WorkareaApi.addWorkareaAssets(workarea.workarea_id, assetIds, currentUsername);
    await updateWorkareaStatuses(workarea, selectedOptions)
}

const updateWorkareaStatuses = async (workarea, selectedOptions) => {
    let currentUsername = JSON.parse(localStorage.getItem('currentUser')).username;
    let workarea_workflow_status = {
        assignee: selectedOptions.assignee === -1 ? null : selectedOptions.assignee,
        workflow_status_id: selectedOptions.workarea_workflow_status_id ?? null,
        workarea_id: workarea.workarea_id,
        created_by: currentUsername,
    }
    await WorkareaApi.updateWorkareaStatuses({ workarea_statuses: [workarea_workflow_status] });
}

const updateWorkareaAssetsAndWorkflowStatuses = async (workareaId, assetIds, selectedOptions) => {
    let currentUsername = JSON.parse(localStorage.getItem('currentUser')).username;
    let existingAssets = await AssetWorkflowApi.getAssetsByKeyValue('workarea_id', workareaId);
    const existingAssetIds = new Set(existingAssets.map(asset => asset.id));
    const newAssetIds = assetIds.filter(id => !existingAssetIds.has(id));
    await WorkareaApi.addWorkareaAssets(workareaId, newAssetIds, currentUsername);

    return updateAssetWorkflowStatuses(assetIds, selectedOptions);
}

const updateAssetWorkflowStatuses = async (assetIds, selectedOptions) => {
    let currentUsername = JSON.parse(localStorage.getItem('currentUser')).username;

    let asset_workflow_statuses = assetIds.map(asset_id => {
        return {
            asset_id,
            workflow_status_id: selectedOptions.workflow_status_id === -1 ? null : selectedOptions.workflow_status_id,
            assignee: selectedOptions.assignee === -1 ? null : selectedOptions.assignee,
            created_by: currentUsername
        }
    });
    await AssetWorkflowApi.updateAssetWorkflowStatuses({ asset_workflow_statuses: asset_workflow_statuses });
}

const fetchWorkareaAssets = async (workarea_id) => {
    return await AssetWorkflowApi.getAssetsByKeyValue('workarea_id', workarea_id);
}

export {
    addWorkareaWithAssets,
    updateWorkareaStatuses,
    updateWorkareaAssetsAndWorkflowStatuses,
    updateAssetWorkflowStatuses,
    fetchWorkareaAssets
}