import React, { useState, useContext, useEffect, useRef } from "react";
import { Menu, MenuItem, Typography, Box } from '@mui/material';
import { StoreContext } from "../../Contexts";
import { locationFunctions, measurementTools } from "../../utils/reusable-functions";

const MeasurementCell = ({ assetId, propertiesSchema, measurementType, handleChange, measurementOptions }) => {
    const appContext = useContext(StoreContext);
    const [useImperial] = appContext.useImperial;
    const [measurementMap, setMeasurementMap] = appContext.measurementMap;
    const [activePointcloud] = appContext.activePointcloud;
    const [activePole] = appContext.activePole;
    const [potreeViewer] = appContext.potreeViewer;
    const [, setPotreeMode] = appContext.potreeMode;
    const [, setMarkerPlacement] = appContext.markerPlacement;
    const [anchorEl, setAnchorEl] = useState(null);
    const anchorElRef = useRef()
    anchorElRef.current = anchorEl;
    const [measurementValues, setMeasurementValues] = useState({});
    const open = useRef();
    open.current = Boolean(anchorElRef.current);
    const menuOptionsAvailable = propertiesSchema.some(({ key }) => !measurementValues[key]);
    const [highlight, setHighlight] = useState(false);

    const getMeasurementValue = (measurementLabel) => {
        let base = measurementMap[`point_cloud_coordinates_${activePole.asset_uuid}`];
        let coordinates = measurementMap[measurementLabel];
        if (!coordinates || !base) {
            return '';
        }
        let [longitude, latitude] = locationFunctions.getLatLngOfCoordinate(coordinates, activePointcloud.crs);
        switch (measurementType) {
            case 'height':
                let height = measurementTools.getHeight(coordinates, base, activePointcloud.crs, !useImperial);
                return height;
            case 'elevation':
                return measurementTools.getElevation(coordinates, activePointcloud.crs, !useImperial);
            case 'length':
                return measurementTools.getLength(coordinates, base, activePointcloud.crs, !useImperial);
            case 'distance':
                return measurementTools.getDistance(coordinates, base, activePointcloud.crs, !useImperial);
            case 'azimuth':
                return measurementTools.getAzimuth(coordinates, base, potreeViewer.getProjection(), !useImperial);
            case "latitude":
                return latitude;
            case "longitude":
                return longitude;
            default:
                return '';
        }
    };

    const handleSetPointMode = (event, key) => {
        event.stopPropagation();
        const measurementLabel = `${key}_${assetId}`
        if (!Object.keys(measurementMap).includes(measurementLabel)) {
            setPotreeMode(true);
            const measurement = potreeViewer.measuringTool.startInsertion({
                showDistances: false,
                showAngles: false,
                showCoordinates: false,
                showArea: false,
                closed: true,
                maxMarkers: 1,
                name: 'Point'
            });

            measurement.addEventListener('marker_dropped', (event) => {
                setMarkerPlacement([...potreeViewer.scene.measurements]);
                setMeasurementMap(prevMap => ({
                    ...prevMap,
                    [measurementLabel]: event.target,
                }));
            });
        }
        handleMenuClose();
    }

    const handleMenuClick = (event) => {
        if (menuOptionsAvailable) {
            setAnchorEl(event.currentTarget);
        }
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const detectChanges = (newMeasurementValues, key, newValue) => {
        if (measurementValues[key] !== newValue) {
            newMeasurementValues[key] = newValue;
            return true;
        }
        return false;
    }

    const updateCell = (hasChanges, newMeasurementValues) => {
        setHighlight(false);
        if (hasChanges) {
            setHighlight(true);
            setMeasurementValues(prevValues => ({
                ...prevValues,
                ...newMeasurementValues
            }));
        }
    }

    useEffect(() => {
        const newMeasurementValues = {};
        let hasChanges = false;

        const promises = propertiesSchema.map(({ key }) => new Promise((resolve) => {
            let measurementLabel = `${key}_${assetId}`;
            const newValue = getMeasurementValue(measurementLabel);
            const newChange = detectChanges(newMeasurementValues, key, newValue);
            hasChanges = hasChanges || newChange;

            if (measurementMap[measurementLabel]?.points[0].position) {
                handleChange(assetId, key, measurementMap[measurementLabel].points[0].position);
            }
            resolve();
        }));

        Promise.all(promises).then(() => {
            updateCell(hasChanges, newMeasurementValues);
        });

    }, [measurementMap, propertiesSchema]);

    useEffect(() => {
        if (highlight) {
            setTimeout(() => setHighlight(false), 2500);
        }
    }, [highlight]);

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    gap: '1px',
                    cursor: 'pointer',
                    height: '100%',
                    width: '100%',
                    p: 0,
                    m: 0,
                    animation: highlight ? `highlight 3s 0s` : 'none'
                }}
                onClick={handleMenuClick}>
                {propertiesSchema.map(({ key, properties }) => (
                    measurementValues[key] ?
                        < Typography key={key} style={{ fontSize: '.8rem' }}>
                            {measurementValues[key]} {propertiesSchema.length > 1 ? `(${properties.label.split(' ')[0]})` : ''}
                        </Typography>
                        : ''
                ))}
            </Box >
            <Menu anchorEl={anchorElRef.current} open={open.current} onClose={handleMenuClose}>
                {propertiesSchema.map(({ key, properties }) => (
                    // Only display MenuItem if there's no measurement value for this property
                    !measurementValues[key] && (
                        <MenuItem key={key} onClick={(event) => handleSetPointMode(event, key)}>
                            {properties.label || key}
                        </MenuItem>
                    )
                ))}
            </Menu>
        </>
    );
};

export default MeasurementCell;