/* eslint-disable */
import React, { useContext, useEffect } from "react";
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import List from '@mui/material/List';
import { StoreContext } from '../../Contexts';
import { ProjectApi } from '../../api';
import { LayoutOption } from "../../Enums";

function ProjectList() {
  const appContext = useContext(StoreContext);
  const [, setActiveProject] = appContext.activeProject;
  const [availableProjects, setAvailableProjects] = appContext.availableProjects;
  const setLayout = appContext.layout[1];

  const fetchData = async () => {
    const projectList = await ProjectApi.fetchProjectList();
    setAvailableProjects(projectList);
  }

  useEffect(() => {
    setActiveProject(null);
    fetchData();
    setLayout(LayoutOption.FullPage);
  }, []);

  const listProjects = availableProjects.map(project =>
    <ListItem key={project.collection_name} disablePadding>
      <ListItemButton onClick={() => {
        setActiveProject(project);
        window.open(`/map-one?project-id=${project.collection_name}`, "_self");
        // add project parameter to url ?? 
      }}>
        <ListItemText primary={project.collection_name} />
      </ListItemButton>
    </ListItem>
  );

  return (
    <div className="project-list" style={{ maxHeight: 'calc(100vh - 64px)', overflowY: 'auto' }}>
      <List component="nav" aria-label="projects list">
        {listProjects}
      </List>
    </div>
  );
}

export default ProjectList;