import React from 'react';

function DummyTestSection() {
  return (
    <div className="dummy-test-section">
      <h1>START START START START START</h1>
      <h1>test layout placement of modules.HELLO WORLD.</h1>
      <h1>test layout placement of modules.HELLO WORLD.</h1>
      <h1>test layout placement of modules.HELLO WORLD.</h1>
      <h1>test layout placement of modules.HELLO WORLD.</h1>
      <h1>test layout placement of modules.HELLO WORLD.</h1>
      <h1>test layout placement of modules.HELLO WORLD.</h1>
      <h1>test layout placement of modules.HELLO WORLD.</h1>
      <h1>END END EDN END</h1>
    </div>
  );
}

export default DummyTestSection;
