/* eslint-disable */
import { StoreContext } from '../../Contexts';
import React, { useContext, useEffect, useState } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Stack,
  CircularProgress,
  Button,
  ButtonGroup,
  Alert
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { locationFunctions } from '../../utils/reusable-functions';
import { GroundCollectionApi } from '../../api';
import { InteractiveSection } from '../../Enums';
import vec3ToSerializedString from '../../utils/reusable-functions/vec3ToSerializedString';

function RequestGroundCollection() {
  const appContext = useContext(StoreContext);
  const [potreeViewer] = appContext.potreeViewer;
  const [activePointcloud] = appContext.activePointcloud;
  const [mainView] = appContext.mainView;
  const [sideBarModules, setSidebarModules] = appContext.sideBarModules;
  const [activeProject] = appContext.activeProject;
  const [users] = appContext.users;
  const [dropdownData, setDropdownData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    lat: null,
    lng: null,
    coord: null,
    created_by: JSON.parse(localStorage.getItem('currentUser')).username,
    reason_id: null
  });

  const [validationErrors, setValidationErrors] = useState([]);

  const handleFormDataChange = (key, e) => {
    setFormData(prevState => ({
      ...prevState,
      [key]: e.target.value
    }));
  }

  const resetAndCloseCollect = () => {
    // nice way to remove measurements from the potree scene.
    // we can use this in a lot of other places when needed. 
    potreeViewer.scene.removeAllMeasurements();
    setSidebarModules({
      ...sideBarModules,
      right_top: InteractiveSection.AssetFormPanel,
    });
  }

  const submitCollectionRequest = async () => {
    setLoading(true);
    setValidationErrors([]);
    const errors = [];
    for (const [key, value] of Object.entries(formData)) {
      if (!value)
        errors.push(key);
    }
    setValidationErrors(errors);

    // if there are no errors then go ahead and process the request. 
    if (errors.length === 0) {
      const data = {
        ...formData,
        project_name: activePointcloud.collection_name,
        project_id: activeProject.id,
        reason: dropdownData.find(reason => reason.id === formData.reason_id).value
      };
      delete data.coord;
      await GroundCollectionApi.createGroundRequestCollection(data);
      // cleans everything else and starts the component state fresh. 
      resetAndCloseCollect();
    }
    setLoading(false);
  }

  // use this to get the lat lng of the measurment. 
  const usePointcloudContext = () => {
    const measurement = potreeViewer.measuringTool.startInsertion({
      showDistances: false,
      showAngles: false,
      showCoordinates: false,
      showArea: false,
      closed: true,
      maxMarkers: 1,
      name: 'Point'
    });

    measurement.addEventListener('marker_dropped', (event) => {
      let [lng, lat] = locationFunctions.getLatLngOfCoordinate(event.target, activePointcloud.crs);
      setFormData(prevState => ({
        ...prevState,
        lat: lat,
        lng: lng,
        coord: vec3ToSerializedString(event.target.points[0].position)
      }));
    });
  }

  const beginRequestProcess = async () => {
    if (activePointcloud && mainView === InteractiveSection.PointCloud) {
      // this handles the functionality when we are in the pointcloud viewer. 
      usePointcloudContext();
    }
    if (mainView === InteractiveSection.Map) {
      console.log('we are treating this as a map capture.');
      // when you click the map get the lat lng. 
    }
  }

  useEffect(() => {
    const getDropdownData = async () => {
      setDropdownData(await GroundCollectionApi.getGroundCollectionDropdowns());
    }
    beginRequestProcess();
    getDropdownData();
  }, []);

  return (
    <>
      <Stack spacing={2} sx={{ p: 2 }}>
        <FormControl size="small" sx={{ minWidth: 120 }}>
          <InputLabel id="assignee-select-label">Requester</InputLabel>
          <Select
            labelId="assignee-select-label"
            id="assignee-select"
            value={formData.created_by}
            onChange={(e) => { handleFormDataChange('created_by', e) }}
            error={validationErrors.includes('created_by')}
          >
            {users
              ? users.map(user => <MenuItem key={user.username} value={user.username}>{user.username}</MenuItem>)
              : <MenuItem><CircularProgress /></MenuItem>}
          </Select>
        </FormControl>
        <FormControl margin="dense"
          error={validationErrors.includes('reason_id')}>
          <InputLabel id="reason-select-label">Reason</InputLabel>
          <Select
            size="small"
            labelId="reason-select-label"
            value={formData.reason_id}
            defaultValue=''
            onChange={(e) => { handleFormDataChange('reason_id', e) }}>
            {dropdownData.length > 0 && dropdownData.map((menuItem, index) => (
              <MenuItem key={index} value={menuItem.id}>{menuItem.value}</MenuItem>
            ))}
            {dropdownData.length === 0 &&
              <MenuItem>
                <CircularProgress />
              </MenuItem>}
          </Select>
        </FormControl>
        <Alert severity={validationErrors.includes('lat') ? 'error' : 'info'}>
          Select location in point cloud
        </Alert>
        {formData.lat && formData.lng &&
          <FormControl margin="dense">
            <TextField
              size="small"
              label="Coordinants"
              value={`${formData.lat}, ${formData.lng}`}
              InputProps={{
                readOnly: true,
              }} />
          </FormControl>}
        <ButtonGroup>
          <Button
            size="small"
            variant="outlined"
            onClick={resetAndCloseCollect} >
            Cancel
          </Button>
          <LoadingButton
            size="small"
            variant="outlined"
            onClick={submitCollectionRequest}
            loading={loading}>
            Submit
          </LoadingButton>
        </ButtonGroup>
      </Stack >
    </>
  )
}

export default RequestGroundCollection;
