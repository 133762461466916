import proj4 from "proj4";
import crsReference from "../crsReference";

function deg2Rad(deg) {
    return deg * Math.PI / 180;
}

function pythagorasEquirectangular(lat1, lon1, lat2, lon2) {
    lat1 = deg2Rad(lat1);
    lat2 = deg2Rad(lat2);
    lon1 = deg2Rad(lon1);
    lon2 = deg2Rad(lon2);
    const R = 6371;
    const x = (lon2 - lon1) * Math.cos((lat1 + lat2) / 2);
    const y = (lat2 - lat1);
    const d = Math.sqrt(x * x + y * y) * R;
    console.log(`distance: ${d}`);
    return d;
}

function nearestLocation(latitude, longitude, options) {
    let distances = [];

    for (const option of options) {
        const distance = pythagorasEquirectangular(latitude, longitude, option.latitude,
            option.longitude);
        distances.push({ option: option, distance: distance })
    }
    const closest = Math.min(...distances.map(option => option.distance));
    return distances.find(obj =>
        obj.distance === closest
    ).option
}

function getRectangleCoordinates(minLat, minLon, maxLat, maxLon) {
    // Create a list of coordinates to represent the rectangle.
    const coordinates = [];

    // Add the bottom left corner of the rectangle.
    coordinates.push([minLat, minLon]);

    // Add the top left corner of the rectangle.
    coordinates.push([minLat, maxLon]);

    // Add the top right corner of the rectangle.
    coordinates.push([maxLat, maxLon]);

    // Add the bottom right corner of the rectangle.
    coordinates.push([maxLat, minLon]);

    return coordinates;
}

function getLatLngOfCoordinate(measurement, pointCloudCrs) {
    const pointCloudClickCoordinates = [measurement.points[0].position.x, measurement.points[0].position.y];
    return proj4(crsReference[pointCloudCrs], '+proj=longlat +datum=WGS84 +ellps=GRS80', pointCloudClickCoordinates);
}

export { nearestLocation, getRectangleCoordinates, getLatLngOfCoordinate }