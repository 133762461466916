// // this maps the EPSG crs code to the proj4.js projection used to convert potree click to lat lng.
const crsReference = {
  'EPSG:32617': "+proj=utm +zone=17 +datum=WGS84 +units=m +no_defs +type=crs", //  UTM zone 17N
  'EPSG:4978': "+proj=geocent +datum=WGS84 +units=m +no_defs +type=crs", //WGS 84
  'EPSG:4979': "+proj=longlat +datum=WGS84 +no_defs +type=crs", // WGS 84
  'EPSG:32711': "+proj=utm +zone=11 +south +datum=WGS84 +units=m +no_defs +type=crs", // UTM zone 11S
  'EPSG:32611': "+proj=utm +zone=11 +datum=WGS84 +units=m +no_defs +type=crs", // UTM zone 11N 
  'EPSG:4269': "+proj=longlat +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +no_defs +type=crs", // NAD83 full us
  'EPSG:2274': "+proj=lcc +lat_1=36.41666666666666 +lat_2=35.25 +lat_0=34.33333333333334 +lon_0=-86 +x_0=600000 +y_0=0 +ellps=GRS80 +datum=NAD83 +to_meter=0.3048006096012192 +no_defs", //NAD83 TENNESSEE
  'EPSG:32136': "+proj=lcc +lat_0=34.3333333333333 +lon_0=-86 +lat_1=36.4166666666667 +lat_2=35.25 +x_0=600000 +y_0=0 +datum=NAD83 +units=m +no_defs +type=crs", //NAD83 ALSO TENNESSEE
  'EPSG:32126': "+proj=lcc +lat_0=34.3333333333333 +lon_0=-86 +lat_1=36.4166666666667 +lat_2=35.25 +x_0=600000 +y_0=0 +datum=NAD83 +units=m +no_defs +type=crs", //Remove this. aaron used the typo from your email so it is currently 3126
  'EPSG:26958': "+proj=tmerc +lat_0=24.3333333333333 +lon_0=-81 +k=0.999941177 +x_0=200000.0001016 +y_0=0 +ellps=GRS80 +towgs84=-2,0,4,0,0,0,0 +units=us-m +no_defs +type=crs",
  'EPSG:8764': "+proj=tmerc +lat_0=38.8333333333333 +lon_0=-74.5 +k=0.9999 +x_0=150000 +y_0=0 +datum=NAD83 +units=us-ft +vunits=us-ft +no_defs +type=crs" // New York
}

// const crsReference = async (espgType) => {
//   const url = `https://epsg.io/${espgType}.proj4`;
//   return await fetch(url)
//     .then(response => response.json())
//     .then(data => { return data })
//     .catch(error => { throw new Error(`API call failed with status code ${error}`) });
// }

export default crsReference;