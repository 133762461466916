import proj4 from "proj4";
import { MathUtils, Vector3 } from "three";

function metersToFeet(meters) {
    // The conversion rate from meters to feet.
    const conversionRate = 3.2808399;
    // Convert the meters to feet.
    const feet = meters * conversionRate;
    // Round the feet to 2 decimal places.
    return feet.toFixed(2);
}

function feetToMeters(feet) {
    // The conversion rate from feet to meters.
    const conversionRate = 0.3048;
    // Convert the feet to meters.
    const meters = feet * conversionRate;
    // Round the meters to 2 decimal places.
    return meters.toFixed(2);
}


function radiansToDegrees(radians) {
    let degrees = MathUtils.radToDeg(radians);
    if (degrees < 0) {
        degrees = 360 + degrees;
    }
    return degrees.toFixed(2);
}
//copied from potree repo
function computeAzimuth(p1, p2, projection) {
    let azimuth = 0;
    if (projection) {
        // if there is a projection, transform coordinates to WGS84
        // and compute angle to north there
        let transform;

        if (projection.includes('EPSG')) {
            transform = proj4(projection, "WGS84");
        } else {
            proj4.defs("pointcloud", projection);
            transform = proj4("pointcloud", "WGS84");
        }

        const llP1 = transform.forward(p1.toArray());
        const llP2 = transform.forward(p2.toArray());
        const dir = [
            llP2[0] - llP1[0],
            llP2[1] - llP1[1],
        ];
        azimuth = Math.atan2(dir[1], dir[0]) - Math.PI / 2;
    } else {
        // if there is no projection, assume [0, 1, 0] as north direction
        const dir = [p2.x - p1.x, p2.y - p1.y];
        azimuth = Math.atan2(dir[1], dir[0]) - Math.PI / 2;
    }
    return azimuth;
}


const getHeight = (measurement, base, crs, metric = false) => {
    if (!measurement || !base) return ''; // Handle missing data

    const height = measurement.points[0].position.z - base.points[0].position.z;
    const convertedHeight = crs === 'EPSG:8764' ? feetToMeters(height) : height;

    return metric ? `${convertedHeight} m` : `${metersToFeet(convertedHeight)} ft`;
};


const getElevation = (measurement, crs, metric = false) => {
    if (measurement) {
        let elevation = measurement.points[0].position.z;
        const convertedElevation = crs === 'EPSG:8764' ? feetToMeters(elevation) : elevation;

        return metric ? `${convertedElevation} m` : `${metersToFeet(convertedElevation)} ft`;
    }
}

const getLength = (measurement, base, crs, metric = false) => {
    if (measurement && base) {
        const pole = new Vector3(base.points[0].position.x,
            base.points[0].position.y,
            measurement.points[0].position.z);
        const length = measurement.points[0].position.distanceTo(pole);
        const convertedLength = crs === 'EPSG:8764' ? feetToMeters(length) : length;

        return metric ? `${convertedLength} m` : `${metersToFeet(convertedLength)} ft`;
    }
}

const getDistance = (measurement, base, crs, metric = false) => {
    if (measurement && base) {
        const distance = measurement.points[0].position.distanceTo(base.points[0].position);
        const convertedDistance = crs === 'EPSG:8764' ? feetToMeters(distance) : distance;

        return metric ? `${convertedDistance} m` : `${metersToFeet(convertedDistance)} ft`;
    }
}

const getAzimuth = (measurement, base, projection, metric = false) => {
    if (measurement && base) {
        let azimuth = computeAzimuth(base.points[0].position, measurement.points[0].position, projection);
        return metric ? `${azimuth} rad` : `${radiansToDegrees(-azimuth)} °`;
    }
}

function calculateDistanceInFeet(lat1, lon1, lat2, lon2) {
    const R = 6371000; // Radius of the earth in meters
    const dLat = degreesToRadians(lat2 - lat1);
    const dLon = degreesToRadians(lon2 - lon1);
    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(degreesToRadians(lat1)) * Math.cos(degreesToRadians(lat2)) *
        Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distanceInMeters = R * c;
    return metersToFeet(distanceInMeters);
}

function degreesToRadians(deg) {
    return deg * (Math.PI / 180);
}


export {
    metersToFeet,
    feetToMeters,
    radiansToDegrees,
    degreesToRadians,
    computeAzimuth,
    getHeight,
    getElevation,
    getLength,
    getDistance,
    getAzimuth,
    calculateDistanceInFeet
}