/* eslint-disable */
import React, { useEffect, useRef, useState, useContext } from "react";
import { StoreContext, PositionContext, MapLayerProvider } from '../../Contexts';
import { assetService, mapService } from "../../services";
import MapTools from "./map-tools/MapTools";
import PoleLayer from "./bing-map-layers/PoleLayer";
import PointCloudPolyLineLayer from "./bing-map-layers/PointCloudPolyLineLayer";
import PointCloudBoxesLayer from "./bing-map-layers/PointCloudBoxesLayer";
import PanoramaImageLayer from "./bing-map-layers/PanoramaImageLayer";
import { InteractiveSection } from "../../Enums";
import WorkareasLayer from "./bing-map-layers/WorkAreasLayer";
import { AssetApi } from "../../api";

const BingMap = ({
  bingMapsKey,
}) => {
  const appContext = useContext(StoreContext);
  const positionContext = useContext(PositionContext);
  const [latitude] = positionContext.latitude;
  const [longitude] = positionContext.longitude;
  const [viewArea] = positionContext.viewArea;
  const [activeProject] = appContext.activeProject;
  const [polesUpdateTrigger] = appContext.polesUpdateTrigger;
  const [mainView] = appContext.mainView;
  const activeProjectRef = useRef({});
  activeProjectRef.current = activeProject;
  const [bingInitiated, setBingInitiated] = useState(false);
  const [inputSourceTypes, setInputSourceTypes] = useState(null);
  const [polesByInputSourceDict, setPolesByInputSourceDict] = useState();
  const [mapReady, setMapReady] = useState(false);
  const [latestPositionPushpin, setlatestPositionPushpin] = useState(null);
  const mapContainer = useRef(null);
  const mapRef = useRef();
  const [layerStates, setLayerStates] = useState();
  const [layers, setLayers] = useState({});
  const layersRef = useRef({});
  layersRef.current = layers;

  const initMap = () => {
    const { Maps } = window.Microsoft;
    if (!mapRef.current) {
      // only make a new map if one doesn't already exist
      mapRef.current = new Maps.Map(mapContainer.current, {
        credentials: bingMapsKey,
        disableStreetside: true
      });

      let lat = viewArea?.latitude || latitude || activeProjectRef.current.lat;
      let long = viewArea?.longitude || longitude || activeProjectRef.current.ln;
      Maps.Events.addOne(mapRef.current, 'viewrendered', async () => {
        mapRef.current.setView({
          center: new Maps.Location(lat, long),
          zoom: viewArea?.zoom || 17.0,
          mapTypeId: window.Microsoft.Maps.MapTypeId.aerial
        });
      });
      Maps.Events.addHandler(mapRef.current, 'viewchangeend', async () => {
        let center = mapRef.current.getCenter();
        let zoom = mapRef.current.getZoom();
        positionContext.updateMapView(center.latitude, center.longitude, zoom);
      });
      setMapReady(true);
    }
  }

  const updateLayerRef = (layerKey, layer) => {
    setLayers(prevState => ({
      ...prevState,
      [layerKey]: layer
    }));
  }

  const plotLatestPosition = async () => {
    if (latitude && longitude) {
      const { Maps } = window.Microsoft;
      mapRef.current.entities.remove(latestPositionPushpin);
      let pushpin = new Maps.Pushpin({
        latitude: latitude,
        longitude: longitude,
      }, { color: 'black' })
      setlatestPositionPushpin(pushpin);
      mapRef.current.entities.push(pushpin);
      mapRef.current.setView({
        center: new Maps.Location(latitude, longitude)
      })
    }
  }

  const getPolesByInputSourceDict = async () => {
    try {
      let poles = await assetService.fetchAssets();
      let polesByInputSourceDict = {};

      poles.forEach(pole => {
        if (!polesByInputSourceDict[pole.input_source_type_id]) {
          polesByInputSourceDict[pole.input_source_type_id] = [];
        }
        polesByInputSourceDict[pole.input_source_type_id].push(pole);
      });

      setPolesByInputSourceDict(polesByInputSourceDict);

    } catch (error) {
      console.error("Error fetching poles:", error);
      // Handle error scenario, maybe set an error state or log it
    }
  };

  // Dynamically construct the layer states for poles
  const polesLayerStates = inputSourceTypes?.reduce((acc, type) => {
    const key = `poles${type.input_source_type_id}`; // Construct the key with suffix "Poles"
    acc[key] = {
      label: type.input_source_type_name.trim(), // Use the type name as the label
      enabled: true, // Default enabled state
    };
    return acc;
  }, {});

  const defaultLayerState = {
    panoImagery: { label: 'Panoramic Imagery', enabled: true },
    pointCloudBoxes: { label: 'Point Cloud Boxes', enabled: false },
    pointCloudArea: { label: 'Point Cloud Area', enabled: true },
    ...polesLayerStates,
    workareas: { label: 'Workareas', enabled: false },
  };

  useEffect(() => {
    const init = async () => {
      if (!bingInitiated) {
        await mapService.loadBingApi(bingMapsKey);
        setBingInitiated(true);
      }
      if (!inputSourceTypes) {
        setInputSourceTypes(await AssetApi.getInputSourceTypes());
      }
    }
    init();
  }, []);

  useEffect(() => {
    if (!polesByInputSourceDict && inputSourceTypes) {
      getPolesByInputSourceDict();
      setLayerStates(defaultLayerState)
    }
  }, [inputSourceTypes]);

  useEffect(() => {
    if (inputSourceTypes) {
      getPolesByInputSourceDict();
    }
  }, [polesUpdateTrigger]);

  useEffect(() => {
    if (window.Microsoft && window.Microsoft.Maps && activeProjectRef.current) {
      initMap();
    }
  }, [bingInitiated, activeProjectRef.current]);

  useEffect(() => {
    if (mapRef.current) {
      // Filter out the Workareas layer initially
      const layerEntries = Object.entries(layerStates).filter(([layerKey]) => layerKey !== 'workareas');
      layerEntries.forEach(([layerKey, layerState]) => {
        let layer = mapService.getLayer(layerKey, mapRef.current);
        layer?.setVisible(layerState.enabled);
        updateLayerRef(layerKey, layer);
      });

      // Now handle the Workareas layer specifically to ensure it's on top
      const workareasState = layerStates['workareas'];
      if (workareasState) {
        let workareasLayer = mapService.getLayer('workareas', mapRef.current);
        workareasLayer?.setVisible(workareasState.enabled);
        updateLayerRef('workareas', workareasLayer);
      }
    }
  }, [layerStates]);

  useEffect(() => {
    if (mapRef.current) {
      plotLatestPosition();
    }
  }, [latitude, longitude, mapRef.current]);

  return (
    <MapLayerProvider>
      <div ref={mapContainer} style={{ height: "100%", width: "100%" }}></div>
      {layerStates && mapReady && <>
        <PointCloudPolyLineLayer
          map={mapRef.current}
          visible={layerStates.pointCloudArea.enabled}
          layerRef={layersRef.current.pointCloudArea}
          updateLayer={updateLayerRef} />
        <PointCloudBoxesLayer
          map={mapRef.current}
          visible={layerStates.pointCloudBoxes.enabled}
          layerRef={layersRef.current.pointCloudBoxes}
          updateLayer={updateLayerRef} />
        <PanoramaImageLayer
          map={mapRef.current}
          visible={layerStates.panoImagery.enabled}
          layerRef={layersRef.current.panoImagery}
          updateLayer={updateLayerRef} />
        {/* Map through inputSourceTypes and render PoleLayer only if there are poles for that type */}
        {polesByInputSourceDict && inputSourceTypes.map((type) =>
          polesByInputSourceDict[type.input_source_type_id] && polesByInputSourceDict[type.input_source_type_id].length > 0 && (
            <PoleLayer
              key={type.input_source_type_name}
              map={mapRef.current}
              visible={layerStates[`poles${type.input_source_type_id}`]?.enabled}
              layerId={`poles${type.input_source_type_id}`}
              layerRef={layersRef.current[`poles${type.input_source_type_id}`]}
              updateLayer={updateLayerRef}
              poles={polesByInputSourceDict[type.input_source_type_id]}
              defaultColor={type.input_source_type_id === 2 ? 'grey' : 'black'}
            />
          )
        )}
        {layersRef.current.poles3 &&
          <WorkareasLayer
            map={mapRef.current}
            visible={layerStates.workareas.enabled}
            layerRef={layersRef.current.workareas}
            updateLayer={updateLayerRef}
            workareaPolesLayerRef={layersRef.current.poles3} />}
      </>}
      {mainView === InteractiveSection.Map && mapReady &&
        <MapTools
          currentMap={mapRef.current}
          currentLayers={layersRef.current}
          layerStates={layerStates}
          setLayerStates={setLayerStates}
          defaultLayerState={defaultLayerState} />
      }
    </MapLayerProvider>

  );
};

export default BingMap;