const baseUrl = process.env.REACT_APP_BASE_APPLICATION_API_URL;

const fetchWorkareas = async () => {
    const url = `${baseUrl}/get_workareas`;
    return await fetch(url)
        .then(response => response.json())
        .then(data => { return data })
        .catch(error => { throw new Error(`API call failed with status code ${error}`) });
}

const addWorkarea = async (workarea) => {
    const url = `${baseUrl}/add_workarea`;
    const headers = new Headers();
    headers.append("Content-Type", 'application/json');
    return await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(workarea)
    })
        .then(response => response.json())
        .then(data => { return data })
        .catch(error => { throw new Error(`API call failed with status code ${error}`) });
}

const addWorkareaAssets = async (workarea_id, asset_ids, created_by) => {
    const url = `${baseUrl}/add_workarea_assets`;
    const headers = new Headers();
    headers.append("Content-Type", 'application/json');
    return await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify({ workarea_id, asset_ids, created_by })
    })
        .then(response => response.json())
        .then(data => { return data })
        .catch(error => { throw new Error(`API call failed with status code ${error}`) });
}

const updateWorkareaStatuses = async (workareaStatuses) => {
    const url = `${baseUrl}/update_workarea_statuses`;
    const headers = new Headers();
    headers.append("Content-Type", 'application/json');
    return await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(workareaStatuses)
    })
        .then(response => response.json())
        .then(data => { return data.body })
        .catch(error => { throw new Error(`API call failed with status code ${error}`) });
}
const deleteWorkarea = async (workarea_id) => {
    const url = `${baseUrl}/deactivate_workarea?workarea_id=${workarea_id}`;
    return await fetch(url)
        .then(response => response.json())
        .then(data => { return data })
        .catch(error => { throw new Error(`API call failed with status code ${error}`) });
};


export {
    fetchWorkareas,
    addWorkarea,
    addWorkareaAssets,
    updateWorkareaStatuses,
    deleteWorkarea
}