
import { createContext, useState, useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom';
import { StoreContext } from './store';
import { PanoramaApi } from '../api';

export const PositionContext = createContext(null)

export default function PositionProvider({ children }) {
    const navigate = useNavigate();
    const appContext = useContext(StoreContext);
    const [activeProject] = appContext.activeProject;
    const [, setImageId] = appContext.imageId;
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    const [heading, setHeading] = useState(null);
    const [zoom, setZoom] = useState(null);
    const [viewArea, setViewArea] = useState(null);
    const [selectedPointCloudId, setSelectedPointCloudId] = useState(null);

    const getNearestPanoImage = async () => {
        if (activeProject && longitude && latitude) {
            let nearestPanorama = await PanoramaApi
                .fetchNearestPanoImage(activeProject.collection_name,
                    latitude,
                    longitude);
            if (nearestPanorama) {
                setImageId(nearestPanorama.id);
            }
        }
    }

    const updateMapView = (latitude, longitude, zoom) => {
        if (latitude && longitude && zoom) {
            setViewArea({
                latitude: latitude,
                longitude: longitude,
                zoom: zoom
            })
        }
    }

    useEffect(() => {
        if (viewArea) {
            const currentUrlParams = new URLSearchParams(window.location.search);
            currentUrlParams.set('viewArea', JSON.stringify(viewArea));
            navigate(`${window.location.pathname}?${currentUrlParams.toString()}`, { replace: true });
        }
    }, [viewArea])

    useEffect(() => {
        const currentUrlParams = new URLSearchParams(window.location.search);

        if (latitude) {
            currentUrlParams.set('lat', latitude);
        }

        if (longitude) {
            currentUrlParams.set('lng', longitude);
        }

        getNearestPanoImage();
        navigate(`${window.location.pathname}?${currentUrlParams.toString()}`, { replace: true });

    }, [latitude, longitude, zoom, activeProject])

    const position = {
        latitude: [latitude, setLatitude],
        longitude: [longitude, setLongitude],
        heading: [heading, setHeading],
        zoom: [zoom, setZoom],
        viewArea: [viewArea, setViewArea],
        selectedPointCloudId: [selectedPointCloudId, setSelectedPointCloudId],
        updateMapView
    }

    return <PositionContext.Provider value={position}>{children}</PositionContext.Provider>
}