/* eslint-disable */
import React, { useState, useContext, useEffect } from "react";
import { StoreContext } from '../Contexts';
import { useLocation } from "react-router-dom";
import { PoleApi } from "../api";
import { InteractiveSection, LayoutOption } from "../Enums";
import * as THREE from 'three';
import {
  distributionMeasurementTypes,
  extraEquipmentMeasurementTypes,
  transmissionMeasurementTypes,
  wirelessMeasurementTypes,
  wirelineMeasurementTypes
} from "../type-objects/AttachementMeasurementTypeObjects";
import { InteractiveSectionSelector } from "../utils";

const LoadPoleTestFixture = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const pole_id = queryParams.get("pole_id");
  const appContext = useContext(StoreContext);
  const [, setLayout] = appContext.layout;
  const [, setSidebarModules] = appContext.sideBarModules;
  const [mainView, setMainView] = appContext.mainView;
  const [, setActivePointcloud] = appContext.activePointcloud;
  const [, setMeasurementMap] = appContext.measurementMap;
  const [activePole, setActivePole] = appContext.activePole;
  const [potreeViewer] = appContext.potreeViewer;
  // prevent double loading on double renders.  
  const [poleLoaded, setPoleLoaded] = useState(null);

  const loadMeasurement = (measurementLabel, coords, viewer) => {
    const Potree = window.Potree;
    let measure = new Potree.Measure();
    measure.name = measurementLabel;
    measure.showDistances = false;
    measure.showCoordinates = false;
    measure.maxMarkers = 1;
    measure.addMarker(new THREE.Vector3(coords.x, coords.y, coords.z));
    viewer.scene.addMeasurement(measure);
    // need to set the map intially since there are no drop events happening here. 
    setMeasurementMap((prevMap) => {
      return {
        ...prevMap,
        [measurementLabel]: measure
      }
    });
    measure.addEventListener('marker_dropped', (event) => {
      setMeasurementMap((prevMap) => {
        return {
          ...prevMap,
          [measurementLabel]: measure
        }
      });
    });

  }

  const handleAttachmentMeasurments = (labelList, attachment) => {
    labelList.forEach((label) => {
      // need to look at fixing how these are returned, or initially save when there is no value. 
      // inconsistent so need to look at length. in some cases empty coord is saved as "None" in others just as empty string ""
      // WE DO KNOW that a field with an actual coord saved will for sure be longer than 4 characters.
      if (attachment[label.title].length > 4) {
        const coords = JSON.parse(attachment[`${label.title}_coord`]);
        if (coords) { loadMeasurement(`${label.id}_${attachment.id}`, coords, potreeViewer); }
      }
    });
  }

  const loadAttachments = () => {
    activePole.transmission_lines.forEach((attachment) => {
      handleAttachmentMeasurments(transmissionMeasurementTypes, attachment);
    });

    activePole.wireless_comms.forEach((attachment) => {
      handleAttachmentMeasurments(wirelessMeasurementTypes, attachment);
    });

    activePole.wireline_comms.forEach((attachment) => {
      handleAttachmentMeasurments(wirelineMeasurementTypes, attachment);
    });

    activePole.distribution_lines.forEach((attachment) => {
      handleAttachmentMeasurments(distributionMeasurementTypes, attachment);
    });

    activePole.distribution_equipment.forEach((attachment) => {
      handleAttachmentMeasurments(extraEquipmentMeasurementTypes, attachment);
    });

    activePole.anchors.forEach((attachment) => {
      handleAttachmentMeasurments(extraEquipmentMeasurementTypes, attachment);
    });
  }

  const loadPoleIntoViewer = () => {
    // poleLoaded needs to be null - no pole has been loaded into the viewer yet
    if (!poleLoaded) {
      if (potreeViewer && activePole) {
        const sceneData = JSON.parse(activePole.scene_data);
        const initialCameraPosition = new THREE.Vector3(sceneData.cam.x, sceneData.cam.y, sceneData.cam.z);

        // potreeViewer.scene.view.lookAt(new THREE.Vector3(initialCameraPosition.x, initialCameraPosition.y, initialCameraPosition.z));

        setTimeout(() => {
          // sets the camera view. not sure why we need the setTimeout, but it won't set camera position without it. 
          // probably a potree race condition thing. 
          // guessing potree sets the camera in some sort of async way that is slightly delayed.
          potreeViewer.scene.view.position.set(initialCameraPosition.x, initialCameraPosition.y, initialCameraPosition.z + 20.0); // we add 20.0 because we assume that the pole was initially create when the user was zoomed in to the base. This puts the camera 20m above from where that is. 
          potreeViewer.scene.view.lookAt(new THREE.Vector3(poleBase.x, poleBase.y, poleBase.z)); // this just maked the camera look at the base of the pole. 
        }, 2000);

        const poleBase = JSON.parse(activePole.pole_base);
        const poleAGL = JSON.parse(activePole.pole_agl);


        // load regular pole measurements 
        if (poleBase) {
          loadMeasurement(
            'pole_base',
            poleBase,
            potreeViewer
          );
        }

        if (poleAGL) {
          loadMeasurement(
            'pole_agl',
            poleAGL,
            potreeViewer
          );
        }

        // load all the attachments too.
        loadAttachments();
      }
      setPoleLoaded(activePole);
    }
  }

  const fetchData = async () => {
    // if the pole_id is present then we will get all the needed parameters from the response instead of from the query parameters.
    if (pole_id) {
      const poleDataResponse = await PoleApi.getPoleWithAttachementsById(pole_id);
      const poleData = poleDataResponse;
      //set this as the activePole; 
      setActivePole(poleData);

      const sceneData = JSON.parse(poleData.pole.scene_data);
      const crs = sceneData.pc.crs;
      const pointCloudId = sceneData.pc.pointcloud_id;

      setActivePointcloud({
        crs,
        pointCloudId
      });
    }
  }

  useEffect(() => {
    setLayout(LayoutOption.SideBars);;
    fetchData();
    setMainView(InteractiveSection.PointCloud);
    setSidebarModules({
      left_header: InteractiveSection.MeasureTools,
      left_top: InteractiveSection.Panorama,
      left_bottom: null,
      right_header: null,
      right_top: InteractiveSection.Measurements,
      right_bottom: null
    });
  }, []);

  useEffect(() => {
    if (activePole) {
      loadPoleIntoViewer(activePole);
    }
  }, [activePole]);

  return (
    <InteractiveSectionSelector section={mainView} />
  );
}

export default LoadPoleTestFixture;
