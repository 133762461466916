import React, { useContext, useState, useEffect } from "react";
import {
    MenuItem,
    FormControl,
    InputLabel,
    Select,
    Dialog,
    DialogContent,
    CircularProgress,
    DialogTitle,
    Tab,
    Tabs,
    Box,
    IconButton,
    Button,
    Typography
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from '@mui/icons-material/Delete';
import { MapLayerContext, StoreContext } from "../../../Contexts";
import { workareaService } from "../../../services";
import { WorkareaApi, AssetWorkflowApi } from "../../../api";
import CustomTabPanel from "../../../custom-components/CustomTabPanel";
import AssetsWorkflowDataGrid from "../../../custom-components/AssetWorkflowDataGrid";
import AssetOptionsComponent from "../../../custom-components/AssetOptionsComponent";

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const WorkAreaDialog = ({
    modalOpen,
    setModalOpen,
    selectedArea,
    selectedAssets,
    setSelectedAssets,
    clearSelectedArea,
    drawingTools }) => {
    const appContext = useContext(StoreContext);
    const mapLayerContext = useContext(MapLayerContext);
    const [, setWorkareas] = mapLayerContext.workareas;
    const [users] = appContext.users;
    const [, setSnackbar] = appContext.snackbar;
    const [, setPolesUpdateTrigger] = appContext.polesUpdateTrigger;
    const [activeProject] = appContext.activeProject;
    const [workarea, setWorkarea] = useState({});
    const [updating, setUpdating] = useState(false);
    const [rowSelectionModel, setRowSelectionModel] = useState([]);
    const [selectedTabIndex, setSelectedTabIndex] = useState(0);
    const [selectedWorkareaOptions, setSelectedWorkareaOptions] = useState({
        assignee: "",
    });
    const [dialogView, setDialogView] = useState('default');

    const handleTabIndexChange = (event, newIndex) => {
        setSelectedTabIndex(newIndex);
    };

    const handleWorkareaDropdownChange = (key, e) => {
        const value = e.target.value; // No need to convert empty strings to null since -1 is used
        setSelectedWorkareaOptions(prevState => ({
            ...prevState,
            [key]: value
        }));
    };

    const closeModal = (event, reason) => {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
            setModalOpen(false);
            setSelectedWorkareaOptions({});
            setRowSelectionModel([]);
            setSnackbar({
                open: false,
                message: '',
                severity: 'success'
            });
            clearSelectedArea('poles', 'gray');
        }
    }

    const getPolygonCoordinates = () => {
        let polygon = [];
        selectedArea.geometry.rings[0].x.map((longitude, index) => {
            let latitude = selectedArea.geometry.rings[0].y[index]
            polygon.push({ latitude, longitude })
        })
        return polygon;
    }

    const saveSelectedArea = async () => {
        let errors = {
            // Check for both undefined and -1 (Unassigned) for assignee
            assignee: selectedWorkareaOptions.assignee === undefined,
        };

        if (Object.values(errors).some(error => error)) {
            let errorMessage = 'Please select a valid assignee.';
            setSnackbar({
                open: true,
                message: errorMessage,
                severty: 'error'
            });
            return;
        }
        setUpdating(true);
        try {
            workarea.polygon = getPolygonCoordinates();
            workarea.collection_id = activeProject.id;
            workarea.workarea_id
                ? await workareaService.updateWorkareaStatuses(workarea, selectedWorkareaOptions)
                : await workareaService.addWorkareaWithAssets(workarea, rowSelectionModel, selectedWorkareaOptions)
            drawingTools.finish();
            setWorkareas(await WorkareaApi.fetchWorkareas());
        }
        catch (error) {
            setSnackbar({
                open: true,
                message: error.message,
                severty: 'error'
            });
        }
        setUpdating(false);
    }

    const assetsUpdated = async () => {
        setPolesUpdateTrigger(prev => !prev);
        setSelectedAssets(await AssetWorkflowApi.getAssetsByKeyValue('workarea_id', workarea.workarea_id));
        setWorkareas(await WorkareaApi.fetchWorkareas());
    }

    const showDeleteConfirmation = () => {
        setDialogView('confirmDelete');
    };

    const handleConfirmDelete = async () => {
        setUpdating(true);
        await WorkareaApi.deleteWorkarea(workarea.workarea_id)
        setWorkareas(await WorkareaApi.fetchWorkareas());
        setUpdating(false);
        closeModal();
    };

    const handleCancelDelete = () => {
        setDialogView('default');
    };

    useEffect(() => {
        if (selectedArea?.customData) {
            setWorkarea(selectedArea.customData)
            selectedWorkareaOptions.assignee = selectedArea.customData.assignee ?? -1;
            selectedWorkareaOptions.workflow_status_id = selectedArea.customData.workflow_status_id ?? -1;
            setSelectedWorkareaOptions(selectedWorkareaOptions);
        }
    }, [selectedArea])

    useEffect(() => {
        if (selectedAssets) {
            setRowSelectionModel(selectedAssets.map((asset) => asset.asset_id || asset.asset_uuid))
        }
    }, [selectedAssets])

    return (
        <Dialog
            open={modalOpen}
            keepMounted
            onClose={closeModal}
            aria-describedby="alert-dialog-slide-description">
            <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={selectedTabIndex} onChange={handleTabIndexChange} aria-label="basic tabs example">
                        <Tab label="Workarea" {...a11yProps(0)} />
                        <Tab label="Poles" {...a11yProps(1)} disabled={!workarea?.workarea_id} />
                    </Tabs>
                </Box>
                {workarea.workarea_id && (
                    <IconButton
                        aria-label="delete"
                        onClick={showDeleteConfirmation}
                        disabled={updating || dialogView !== 'default'} // Only show in the default view
                        sx={{
                            position: 'absolute',
                            right: 48,
                            top: 8,
                            color: (theme) => theme.palette.red,
                        }}>
                        <DeleteIcon />
                    </IconButton>)}
                <IconButton
                    aria-label="close"
                    onClick={closeModal}
                    disabled={updating}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                {dialogView === 'default' && (
                    <>
                        <CustomTabPanel value={selectedTabIndex} index={0} sx={{ p: 0 }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', m: 1, width: '100%' }}>
                                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                                    <FormControl size="small" sx={{ minWidth: 120 }}>
                                        <InputLabel id="workarea-assignee-select-label">Assignee</InputLabel>
                                        <Select
                                            labelId="workarea-assignee-select-label"
                                            id="workarea-assignee-select"
                                            value={selectedWorkareaOptions.assignee ?? ''}
                                            onChange={(e) => { handleWorkareaDropdownChange('assignee', e) }}>
                                            {users && users.length > 0 && <MenuItem value={''}>Unassigned</MenuItem>}
                                            {users
                                                ? users.map(user => (
                                                    <MenuItem key={user.username} value={user.username}>{user.username}</MenuItem>
                                                ))
                                                : <MenuItem value="" disabled>
                                                    <CircularProgress size={24} />
                                                </MenuItem>
                                            }
                                        </Select>
                                    </FormControl>
                                </Box>
                                <LoadingButton variant="outlined" loading={updating} color='primary' onClick={saveSelectedArea}>
                                    Save
                                </LoadingButton>
                            </Box>
                        </CustomTabPanel>
                        <CustomTabPanel value={selectedTabIndex} index={1} sx={{ p: 0 }}>
                            <AssetsWorkflowDataGrid
                                rows={selectedAssets}
                                getRowId={(row) => row.asset_uuid ?? row.asset_id}
                                rowSelectionModel={rowSelectionModel}
                                onRowSelectionModelChange={setRowSelectionModel} />
                            {selectedAssets?.length > 0 &&
                                <AssetOptionsComponent
                                    assetIds={rowSelectionModel}
                                    assetsUpdated={assetsUpdated}
                                    saving={updating}
                                    setSaving={setUpdating}
                                    workareaId={workarea.workarea_id} />}
                        </CustomTabPanel>
                    </>
                )}
                {dialogView === 'confirmDelete' && (
                    // Confirmation view content
                    <Box>
                        <Typography variant="body1" sx={{ mb: 2 }}>
                            Are you sure you want to delete this workarea?
                        </Typography>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button onClick={handleCancelDelete} color="primary" sx={{ mr: 1 }}>
                                Cancel
                            </Button>
                            <LoadingButton loading={updating} onClick={handleConfirmDelete} color="primary" autoFocus>
                                Confirm
                            </LoadingButton>
                        </Box>
                    </Box>
                )}
            </DialogContent>
        </Dialog >
    );
}
export default WorkAreaDialog;