import React from "react";
import { Stack, } from "@mui/material";
import PolygonTools from "./PolygonTools";
import SearchTool from "./SearchTool";
import MapLayerTools from "./MapLayerTools";

const MapTools = (
    { currentMap,
        currentLayers,
        layerStates,
        setLayerStates,
        defaultLayerState }, ...props) => {
    return (
        <Stack sx={{ position: 'absolute', top: 5, left: 5, background: 'transparent' }} spacing={.5}>
            <SearchTool currentMap={currentMap} />
            <MapLayerTools
                currentLayers={currentLayers}
                layerStates={layerStates}
                setLayerStates={setLayerStates}
            />
            <PolygonTools
                currentMap={currentMap}
                currentLayers={currentLayers}
                layerStates={layerStates}
                setLayerStates={setLayerStates}
                defaultLayerState={defaultLayerState} />
        </Stack >)
}

export default MapTools;