import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import { chipClasses } from '@mui/material/Chip';

const CustomChip = styled(Chip)(({ theme, backgroundcolor = 'default', textcolor = 'white' }) => ({
    [`&.${chipClasses.filled}`]: {
        backgroundColor: backgroundcolor,
        color: textcolor, // Sets the text color
    },
}));

export default CustomChip;