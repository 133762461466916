import { AssetApi, AssetWorkflowApi, PoleApi } from "../api";

const saveAsset = async (asset, returnAsset = false) => {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    if (!asset.asset_uuid || asset.input_source_type_id !== 3) {
        asset.input_source_type_id = 3;
        asset.created_by = currentUser.username;
        let oldAssetUUID = asset.asset_uuid;
        asset.asset_uuid = null;
        try {
            asset.asset_uuid = await AssetApi.createAsset(asset);
        }
        catch (error) {
            throw Error(oldAssetUUID ? `Failed to duplicate asset ${oldAssetUUID}` : `Failed to create ${asset.asset_type_name}`)
        }
    }
    else {
        asset.updated_by = currentUser.username;
        if (!asset.created_by) {
            asset.created_by = currentUser.username;
        }
        await AssetApi.updateAsset(asset);
    }
    if (returnAsset) {
        return await AssetApi.getAssetByUUID(asset.asset_uuid);
    }
}

const fetchAssets = async () => {
    let assets = await AssetApi.getAssetLocationsByType(1);
    let assetWorkflowStatuses = await AssetWorkflowApi.getAssetWorkflowStatuses(assets.map(a => { return a.id || a.asset_uuid }))
    assets.forEach(a => {
        let assetStatus = assetWorkflowStatuses.find(asset => [a.id, a.asset_uuid].includes(asset.asset_id));
        if (assetStatus) {
            a.workflow_status_id = assetStatus.workflow_status_id;
            a.workflow_status = assetStatus.workflow_status;
            a.workflow_status_color = assetStatus.workflow_status_color;
            a.assignee = assetStatus.assignee;
        }
    })
    return assets;
}

const fetchAsset = async (asset_id) => {
    let asset = await AssetApi.getAssetByUUID(asset_id);
    let assetWorkflowStatuses = await AssetWorkflowApi.getAssetWorkflowStatuses([asset_id])
    let assetStatus = assetWorkflowStatuses[0];
    asset.workflow_status_id = assetStatus?.workflow_status_id;
    asset.workflow_status = assetStatus?.workflow_status;
    asset.workflow_status_color = assetStatus?.workflow_status_color;
    asset.assignee = assetStatus?.assignee;
    return asset;
}

const deleteAsset = async (asset) => {
    return await AssetApi.deleteAsset(asset.source_sys_id, asset.input_source_type_id);
}

export {
    saveAsset,
    fetchAssets,
    fetchAsset,
    deleteAsset
}