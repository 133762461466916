import React from "react";
import { measurementTools } from "../utils/reusable-functions";
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";

const calculations = (pole) => {
    const poleBase = JSON.parse(pole.pole_base);

    const result = {
        pole_agl: null,
        pole_base: measurementTools.metersToFeet(poleBase.z)
    }

    if (pole.pole_agl) {
        const poleAgl = JSON.parse(pole.pole_agl);
        const poleAglDelta = poleAgl.z - poleBase.z;
        const poleAglFeet = measurementTools.metersToFeet(poleAglDelta);
        result.pole_agl = poleAglFeet;
    }

    return result;
}

const coordinatesTest = /(.*x.*y.*z).*/;

const AssetDetailsDisplaySection = ({ asset, title }) => {
    const getDisplayValue = (item) => {
        if (typeof item === 'object') {
            return JSON.stringify(item);
        }
        else if (!isNaN(item)) {
            return ` ${parseFloat(item)} `;
        }
        else {
            return ` ${item} `;
        }
    }
    const DetailsDisplayTable = ({ item }) => {
        return (<>
            <TableContainer component={Paper} sx={{ margin: 1, background: "aliceblue" }}>
                <Table size="small" aria-label="a dense table">
                    <TableBody>
                        {Object.keys(item).map(key => {
                            return (
                                <TableRow key={key}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell>{key.replace(/^[-_.]*(.)/, (_, c) => c.toUpperCase())
                                        .replace(/[-_]+(.)/g, (_, c) => ' ' + c.toUpperCase())}</TableCell>
                                    <TableCell>{getDisplayValue(item[key])}</TableCell>
                                </TableRow>)
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
        )
    }

    if (Array.isArray(asset) && asset.length > 0) {
        return <>
            <h3>{title}</h3>
            {asset.map((item, index) => {
                return <DetailsDisplayTable key={`${title}_${index}`} item={item} />
            })}
        </>
    }
    else if (!Array.isArray(asset)) {
        return <>
            <h3>{title}</h3>
            <DetailsDisplayTable key={`${title}`} item={asset} />
        </>
    }
}
export default AssetDetailsDisplaySection;