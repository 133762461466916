const authorizationToken = "effffdb1-3f53-414d-a200-d3a6bb4b5bc4";
const baseUrl = `https://t3sij95115.execute-api.us-east-1.amazonaws.com/uat`;

const fetchPoleDropdownValues = async () => {
    const url = `${baseUrl}/get_pole_dropdown_data`;
    return await fetch(url)
        .then(response => response.json())
        .then(data => { return data.body })
        .catch(error => { throw new Error(`API call failed with status code ${error}`) });
}

const fetchPolesByBounds = async (projectName, gpsx1, gpsy1, gpsx2, gpsy2) => {
    if (gpsx1) {
        const url = `${baseUrl}/get_pole_data_boundingbox?project_name=${projectName}&gpsx1=${gpsx1}&gpsy1=${gpsy1}&gpsx2=${gpsx2}&gpsy2=${gpsy2}`;
        return await fetch(url)
            .then(response => response.json())
            .then(data => { return data.body })
            .catch(error => { throw new Error(`API call failed with status code ${error}`) });
    }
};

const fetchPolesByProject = async (projectName) => {
    const url = `${baseUrl}/get_pole_data_by_project_name?project_name=${projectName}`;
    return await fetch(url)
        .then(response => response.json())
        .then(data => { return data.body })
        .catch(error => { throw new Error(`API call failed with status code ${error}`) });
}

const getPoleById = async (id) => {
    const url = `${baseUrl}/get_pole_data_by_id?pole_id=${id}`;
    return await fetch(url)
        .then(response => response.json())
        .then(data => { return data.body[0] })
        .catch(error => { throw new Error(`API call failed with status code ${error}`) });
}

const getPoleWithAttachementsById = async (id) => {
    const url = `${baseUrl}/get_pole_dataset_with_attachements_by_id?pole_id=${id}`;
    return await fetch(url)
        .then(response => response.json())
        .then(data => { return data.body.poles[0].pole })
        .catch(error => { throw new Error(`API call failed with status code ${error}`) });
}

const createNewPole = async (data) => {
    const headers = new Headers();
    headers.append("Content-Type", 'application/json');

    const url = `${baseUrl}/create_pole_data_record`;
    return await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(data)
    })
        .then(response => response.json())
        .then(data => { return data.body })
        .catch(error => { throw new Error(`API call failed with status code ${error}`) });
}

const updatePole = async (data) => {
    const url = `${baseUrl}/update_pole_data_record`;
    const headers = {
        'Content-Type': 'application/json',
    };
    return await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(data)
    })
        .then(response => response.json())
        .then(data => { return data.body })
        .catch(error => { throw new Error(`API call failed with status code ${error}`) });
}

const deletePoleById = async (id) => {
    const url = `${baseUrl}/delete_pole_by_id?pole_id=${id}`;
    return await fetch(url, {
        method: "DELETE",
    })
        .then(response => response.json())
        .then(data => { return data.body })
        .catch(error => { throw new Error(`API call failed with status code ${error}`) });
}

export {
    fetchPoleDropdownValues,
    fetchPolesByBounds,
    fetchPolesByProject,
    getPoleById,
    getPoleWithAttachementsById,
    createNewPole,
    updatePole,
    deletePoleById
}