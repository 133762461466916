import React, { useContext, useEffect, useState } from "react";
import {
    Box,
    Button,
    Container,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Typography,
    CircularProgress
} from "@mui/material";
import { LayoutOption } from "../Enums";
import { StoreContext } from "../Contexts";
import { useNavigate } from "react-router-dom";

const PseudoLogin = () => {
    const navigate = useNavigate();
    const appContext = useContext(StoreContext);
    const [users] = appContext.users;
    const [selectedUser, setSelectedUser] = useState('');
    const setLayout = appContext.layout[1];

    useEffect(() => {
        setLayout(LayoutOption.FullPage);
        // Clear the current user from localStorage
        localStorage.removeItem('currentUser');
    }, [setLayout]);

    const handleChange = (event) => {
        setSelectedUser(event.target.value);
    };
    const handleSubmit = () => {
        const userObject = users.find(user => user.user_id === selectedUser);
        localStorage.setItem('currentUser', JSON.stringify(userObject));
        navigate('/');
    };

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    marginTop: 8,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}>
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>
                <Box noValidate sx={{ mt: 1 }}>
                    <FormControl fullWidth>
                        <InputLabel id="username-label">Username</InputLabel>
                        <Select
                            labelId="username-label"
                            id="username"
                            value={selectedUser}
                            onChange={handleChange}
                            displayEmpty
                            renderValue={selected => selected ? users.find(user => user.user_id === selected)?.username : ''}
                        >
                            {users
                                ? users.map(user => <MenuItem key={user.user_id} value={user.user_id}>{user.username}</MenuItem>)
                                : <MenuItem key={'loading-menu'}>
                                    <CircularProgress />
                                  </MenuItem>}
                        </Select>
                    </FormControl>
                    <Button
                        fullWidth
                        variant="contained"
                        onClick={handleSubmit}
                        sx={{ mt: 3, mb: 2 }}>
                        Set Current User
                    </Button>
                </Box>
            </Box>
        </Container>
    );
}

export default PseudoLogin;