/* eslint-disable */
import { StoreContext } from '../../Contexts';
import React, { useContext } from "react";
import InteractiveSectionSelector from '../../utils/InteractiveSectionSelector';

function Stage() {
  const appContext = useContext(StoreContext);
  const [mainView] = appContext.mainView;
  return (
    <div className='stage'>
      <InteractiveSectionSelector section={mainView} />
    </div>
  );
}

export default Stage;