import React from "react";
import { measurementTools } from "../utils/reusable-functions";
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";

const calculations = (pole) => {
    const poleBase = JSON.parse(pole.pole_base);

    const result = {
        pole_agl: null,
        pole_base: measurementTools.metersToFeet(poleBase.z)
    }

    if (pole.pole_agl) {
        const poleAgl = JSON.parse(pole.pole_agl);
        const poleAglDelta = poleAgl.z - poleBase.z;
        const poleAglFeet = measurementTools.metersToFeet(poleAglDelta);
        result.pole_agl = poleAglFeet;
    }

    return result;
}

const resolvePath = (object, path, defaultValue) => path
    .split('.')
    .reduce((o, p) => o ? o[p] : defaultValue, object)

const coordinatesTest = /(.*x.*y.*z).*/;

const DetailsDisplaySection = ({ object, displayFields, title, dropdownDataObj = null, dropdownDataKey = null }) => {
    const getDisplayValue = (prop, key, item) => {
        if (coordinatesTest.test(prop)) {
            return ` ${calculations(item)[key]} ft`;
        }
        else if (key?.includes('type') || key === 'anchor_rod_diameter_id') {
            return (dropdownDataObj && dropdownDataObj.find((obj) => obj.id === parseInt(prop))
                ? ` ${dropdownDataObj.find((obj) => obj.id === parseInt(prop))[dropdownDataKey]} `
                : ` ${prop} `);
        }
        else if (!isNaN(prop)) {
            return ` ${parseFloat(prop)} `;
        }
        else {
            return ` ${prop} `;
        }
    }
    const DetailsDisplayTable = ({ item }) => {
        return (<>
            <TableContainer component={Paper} sx={{ margin: 1, background: "aliceblue" }}>
                <Table size="small" aria-label="a dense table">
                    <TableBody>
                        {displayFields.map(field => {
                            return (
                                <TableRow key={field}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell>{field.replace(/^[-_.]*(.)/, (_, c) => c.toUpperCase())
                                        .replace(/[-_]+(.)/g, (_, c) => ' ' + c.toUpperCase())}</TableCell>
                                    <TableCell>{getDisplayValue(resolvePath(item, field), field, item)}</TableCell>
                                </TableRow>)
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
        )
    }

    if (Array.isArray(object) && object.length > 0) {
        return <>
            <h3>{title}</h3>
            {object.map((item, index) => {
                return <DetailsDisplayTable key={`${title}_${index}`} item={item} />
            })}
        </>
    }
    else if (!Array.isArray(object)) {
        return <>
            <h3>{title}</h3>
            <DetailsDisplayTable key={`${title}`} item={object} />
        </>
    }
}
export default DetailsDisplaySection;