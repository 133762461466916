/* eslint-disable */
import { Routes, Route } from "react-router-dom";
import './App.scss';
import Layout from './Layout';
import ProjectList from './features/project-list/ProjectList';
import MapOne from "./app-views/MapOne";
import PanoImageryOne from "./app-views/PanoImageryOne";
import DummyModules from "./app-views/DummyModules";
import PointCloudTwo from './app-views/PointcloudTwo';
import LoadPoleTestFixture from "./app-views/LoadPoleTestFixture";
import PseudoLogin from "./app-views/PseudoLogin";


const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<ProjectList />} />
        <Route path="login" element={<PseudoLogin />} />
        <Route path="map-one" element={<MapOne />} />
        <Route path="pano-imagery-one" element={<PanoImageryOne />} />
        <Route path="dummy-modules-tester" element={<DummyModules />} />
        <Route path="pointcloud-two" element={<PointCloudTwo />} />
        <Route path="load-pole-test-fixture" element={<LoadPoleTestFixture />} />
      </Route>
    </Routes>
  )
}

export default App;
