/* eslint-disable */

import React, { useContext, useEffect } from "react";
import { StoreContext } from '../Contexts';
import { useLocation } from "react-router-dom";
import { InteractiveSection, LayoutOption } from "../Enums";
import PhotoSphereViewer from "../features/photo-sphere-viewer/PhotoSphereViewer";

const PanoImageryOne = () => {

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const appContext = useContext(StoreContext);
  const [, setLayout] = appContext.layout;
  const [, setMainView] = appContext.mainView;
  const [, setImageId] = appContext.imageId;

  useEffect(() => {
    setImageId(queryParams.get("image_id"));
    setLayout(LayoutOption.FullPage);
    setMainView(InteractiveSection.Panorama);
  }, []);


  return (
    <PhotoSphereViewer />
  );
}

export default PanoImageryOne;
