/* eslint-disable */
import React, { useState, useRef, useEffect, useContext } from "react";
import { StoreContext } from '../../Contexts';
import { PointCloudApi } from "../../api";
import {
  Button
} from '@mui/material';

const PotreeViewer = () => {
  const appContext = useContext(StoreContext);
  const [potreeViewer, setPotreeViewer] = appContext.potreeViewer
  const [activePointcloud, setActivePointcloud] = appContext.activePointcloud;
  const viewerElemRef = useRef();
  const [adjacentTiles, setAdjacentTiles] = useState(null);

  const getAdjacentTiles = async () => {
    if (activePointcloud) {
      const theTiles = await PointCloudApi.fetchAdjacentPointcloudTiles(activePointcloud.pointcloud_id, 6);
      setAdjacentTiles(theTiles);
    }
  }

  const displayClassificationHandler = () => {
    potreeViewer.scene.pointclouds.forEach(pc => pc.material.activeAttributeName = "classification");
    potreeViewer.setClassifications(Potree.ClassificationScheme.DEFAULT);
  }

  const displayRGBAHandler = () => {
    potreeViewer.scene.pointclouds.forEach(pc => pc.material.activeAttributeName = "rgba");
    potreeViewer.setClassifications(Potree.ClassificationScheme.DEFAULT);
  }

  const wirelineViewHandler = () => {
    potreeViewer.scene.pointclouds.forEach(pc => pc.material.activeAttributeName = "classification");
    potreeViewer.setClassifications({
      65: { visible: true, name: 'guy wire', color: [0.6, 0.6, 0.6, 1.0] }, //stays: Guy wires
      14: { visible: true, name: 'wire - conductor (phase)', color: [0.5, 0.12, 0.9, 1.0] }, //cond: Conductors and cables between poles, including comms cables
      15: { visible: true, name: 'structure - transmission tower, pole', color: [0.78, 0.60, .17, 1.0] },// structure: Distribution poles including cross-arms, transformers, insulators, etc
      //75: { visible: true, name: 'structure non-power - pole-like structures that are not connected by wires', color: [1.0, 1.0, 0.0, 1.0] }, //Pole non-power - pole-like structures that are not connected by wires, including street lights, flag poles, etc	
      DEFAULT: { visible: false, name: 'other', color: [0.0, 0.0, 0.0, 1.0] },
    });
  }

  useEffect(() => {
    const Potree = window.Potree;
    const viewerElem = viewerElemRef.current;
    let newViewer = new Potree.Viewer(viewerElem);
    setPotreeViewer(newViewer);
  }, []);

  useEffect(() => {
    getAdjacentTiles();
    if (activePointcloud) {
      setActivePointcloud(activePointcloud);
    }
  }, [activePointcloud]);

  useEffect(() => {
    if (potreeViewer !== null) {
      const viewer = potreeViewer;
      const Potree = window.Potree;
      viewer.setEDLEnabled(true);
      viewer.setFOV(60);
      viewer.setPointBudget(2 * 1000 * 1000);
      viewer.setClipTask(Potree.ClipTask.SHOW_INSIDE);
      viewer.loadSettingsFromURL();
    }
  }, [potreeViewer]);

  useEffect(() => {
    const Potree = window.Potree;
    const viewer = potreeViewer;
    if (activePointcloud && adjacentTiles) {
      console.log(adjacentTiles.urls)
      adjacentTiles.urls.forEach((url) => {
        Potree.loadPointCloud(url).then(e => {
          viewer.scene.addPointCloud(e.pointcloud);
          viewer.fitToScreen();
          viewer.setControls(viewer.earthControls);
          viewer.compass.setVisible(true);
          let material = e.pointcloud.material;
          material.size = .25;
          material.pointSizeType = Potree.PointSizeType.FIXED;
          material.shape = Potree.PointShape.CIRCLE;
          if (activePointcloud.classified) {
            viewer.setClassifications(Potree.ClassificationScheme.DEFAULT);
            material.activeAttributeName = "classification";
          }

        }, e => console.error("ERROR: ", e));
      });
    } else {
      getAdjacentTiles();
    }
  }, [adjacentTiles, activePointcloud])

  return (
    <div id="potree-root">
      <div className="potree-wrapper">
        <div id="potree_render_area" ref={viewerElemRef} />
        {activePointcloud?.classified &&
          <div style={{ display: 'flex', justifyContent: 'center', position: 'absolute', bottom: 0, width: '100%', zIndex: '1' }}>
            <Button variant="contained" color="primary" style={{ margin: '0 10px' }} onClick={displayClassificationHandler}>Classified</Button>
            <Button variant="contained" color="primary" style={{ margin: '0 10px' }} onClick={displayRGBAHandler}>RGBA</Button>
            <Button variant="contained" color="primary" style={{ margin: '0 10px' }} onClick={wirelineViewHandler}>Wireline</Button>
          </div>
        }
      </div>
    </div>
  );
};

export default PotreeViewer;