import React, { useContext } from "react";
import { styled } from '@mui/material/styles';
import { StoreContext } from '../Contexts';
import InteractiveSectionSelector from "../utils/InteractiveSectionSelector";
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Stack, Drawer, Divider, Box } from "@mui/material";

export default function Sidebar({ open, onClose, side, drawerWidth = '20%' }) {
  const appContext = useContext(StoreContext);
  const [sideBarModules] = appContext.sideBarModules;

  const handleDrawerClose = () => {
    onClose(false);
  };

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: side == 'left' ? 'flex-end' : 'flex-start',
  }));

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
      }}
      variant="persistent"
      anchor={side}
      open={open}
    >
      <DrawerHeader>
        {side === 'left' && <InteractiveSectionSelector section={sideBarModules.left_header} />}
        <IconButton onClick={handleDrawerClose}>
          {side === 'left' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
        {side === 'right' && <InteractiveSectionSelector section={sideBarModules.right_header} />}
      </DrawerHeader>
      <Divider />
      {side === 'left' ?
        <Stack
          justifyContent="space-evenly"
          alignItems="flex-start"
          spacing={0}
          sx={{
            width: '100%',
            height: '100%',
          }}>
          {/* each module section should have overflow scrolling so that only the child module (sidebar-module-wrap) will scroll when needed. and adapt to the limited area when needed.
          the module style can be set up to be 100% width and height, or it can just grow larger and larger and scroll within it's designated section. MeasurementSchema.js for example is a scrollable module. Photosphere Viewer is a 100% width/height module.*/}
          <div className="sidebar-overflow-wrap">
            {sideBarModules.left_top &&
              <Box className="sidebar-module-wrap" sx={{ height: (sideBarModules.left_bottom ? '50%' : '100%') }}>
                <InteractiveSectionSelector section={sideBarModules.left_top} />
              </Box>}
            {sideBarModules.left_bottom &&
              <Box className="sidebar-module-wrap" sx={{ height: (sideBarModules.left_top ? '50%' : '100%') }}>
                <InteractiveSectionSelector section={sideBarModules.left_bottom} />
              </Box>}
          </div>
        </Stack> :
        <Stack
          justifyContent="space-evenly"
          alignItems="flex-start"
          spacing={0}
          sx={{
            width: '100%',
            height: '100%',
          }}>
          <div className="sidebar-overflow-wrap">
            {sideBarModules.right_top &&
              <Box className="sidebar-module-wrap" sx={{ height: (sideBarModules.right_bottom ? '50%' : '100%') }}>
                <InteractiveSectionSelector section={sideBarModules.right_top} />
              </Box>}
            {sideBarModules.right_bottom &&
              <Box className="sidebar-module-wrap" sx={{ height: (sideBarModules.right_top ? '50%' : '100%') }}>
                <InteractiveSectionSelector section={sideBarModules.right_bottom} />
              </Box>}
          </div>
        </Stack>
      }
    </Drawer>
  )
}