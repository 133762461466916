import React, { useContext } from "react";
import { IconButton, Tooltip } from "@mui/material";
import { StoreContext } from "../Contexts";
import AddCircleIcon from '@mui/icons-material/AddCircle';

const AddPoleButton = () => {
    const appContext = useContext(StoreContext);
    const [potreeViewer] = appContext.potreeViewer;
    const [, setActivePole] = appContext.activePole;
    const [, setPotreeMode] = appContext.potreeMode;
    const [, setMarkerPlacement] = appContext.markerPlacement;
    const [, setMeasurementMap] = appContext.measurementMap;

    const clearPole = () => {
        potreeViewer.scene.removeAllMeasurements();
        setActivePole(null);
        setPotreeMode(false);
        setMarkerPlacement([]);
        setMeasurementMap({});
    }

    return (
        <Tooltip title="Add New Pole" arrow>
            <IconButton size="small" color="success" onClick={clearPole} sx={{ float: "right" }}>
                <AddCircleIcon />
            </IconButton>
        </Tooltip>
    )
}

export default AddPoleButton;