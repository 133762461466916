const baseUrl = process.env.REACT_APP_BASE_APPLICATION_API_URL;

const updateAssetWorkflowStatuses = async (assetWorkflowStatuses) => {
    const url = `${baseUrl}/update_asset_workflow_statuses`;
    const headers = new Headers();
    headers.append("Content-Type", 'application/json');
    return await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(assetWorkflowStatuses)
    })
        .then(response => response.json())
        .then(data => { return data.body })
        .catch(error => { throw new Error(`API call failed with status code ${error}`) });
}

const getAssetWorkflowStatuses = async (asset_ids) => {
    const url = `${baseUrl}/get_asset_workflow_status`;
    const headers = new Headers();
    headers.append("Content-Type", 'application/json');
    return await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify({ asset_ids: asset_ids })
    })
        .then(response => response.json())
        .then(data => { return data })
        .catch(error => { throw new Error(`API call failed with status code ${error}`) });
}

const getAssetsByKeyValue = async (key, value) => {
    const url = `${baseUrl}/get_assets_by_key_value?key=${key}&value=${value}`;
    return await fetch(url)
        .then(response => response.json())
        .then(data => { return data })
        .catch(error => { throw new Error(`API call failed with status code ${error}`) });
}

export {
    updateAssetWorkflowStatuses,
    getAssetWorkflowStatuses,
    getAssetsByKeyValue
}