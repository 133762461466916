import React, { useState, useEffect, useContext } from 'react';
import { AssetApi } from '../../api';
import {
    TextField,
    Button,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
    Container,
    IconButton,
    Grid,
    Box
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { InputType } from '../../Enums';
import MeasurementInputControl from '../../custom-components/MeasurementInputControl';
import { StoreContext } from '../../Contexts';
import { measurementTools } from '../../utils/reusable-functions';

const AssetForm = ({ asset, updateAsset, excludedProperties = [] }) => {
    const appContext = useContext(StoreContext);
    const [assetSchemasDict] = appContext.assetSchemasDict;
    const [formData, setFormData] = useState({});
    const [schema, setSchema] = useState(null);

    useEffect(() => {
        setSchema(assetSchemasDict[asset.asset_properties_json_schema_id]);
    }, []);

    useEffect(() => {
        if (asset) {
            setFormData({ ...asset.asset_properties });
        }
    }, [asset])

    const handleChange = (path, value) => {
        // Split the path and iterate to set the value
        const propertiesPath = path.split('.');
        let lastProp = asset.asset_properties;
        propertiesPath.forEach((prop, index) => {
            if (index === propertiesPath.length - 1) {
                lastProp[prop] = value;
            } else {
                if (!lastProp[prop]) lastProp[prop] = {};
                lastProp = lastProp[prop];
            }
        });
        updateAsset({ ...asset });
    };

    const handleArrayChange = (path, value, index) => {
        // Update specific array element
        const propertiesPath = path.split('.');
        let lastProp = asset.asset_properties;
        propertiesPath.forEach((prop, idx) => {
            if (idx === propertiesPath.length - 1) {
                lastProp[prop][index] = value;
            } else {
                if (!lastProp[prop]) lastProp[prop] = {};
                lastProp = lastProp[prop];
            }
        });
        updateAsset({ ...asset });
    };

    const addArrayField = (path) => {
        const propertiesPath = path.split('.');
        let lastProp = asset.asset_properties;
        propertiesPath.forEach((prop, idx) => {
            if (idx === propertiesPath.length - 1) {
                if (!lastProp[prop]) lastProp[prop] = [];
                lastProp[prop].push('');
            } else {
                if (!lastProp[prop]) lastProp[prop] = {};
                lastProp = lastProp[prop];
            }
        });
        updateAsset({ ...asset });
    };

    const removeArrayField = (path, index) => {
        const propertiesPath = path.split('.');
        let lastProp = asset.asset_properties;
        propertiesPath.forEach((prop, idx) => {
            if (idx === propertiesPath.length - 1) {
                lastProp[prop].splice(index, 1);
            } else {
                if (!lastProp[prop]) lastProp[prop] = {};
                lastProp = lastProp[prop];
            }
        });
        updateAsset({ ...asset });
    };

    const getOptionDisplayValue = (optionValue, property) => {
        switch (property.units) {
            case "meters":
                return measurementTools.metersToFeet(optionValue);
            case "radians":
                return measurementTools.radiansToDegrees(optionValue);
            default:
                return optionValue;
        }
    }

    const findClosestMatch = (value, property, path) => {
        if (property.enum && property.type.includes('number')) {
            const numericValue = typeof value === 'string' ? parseFloat(value) : value;
            if (!isNaN(numericValue) && !property.enum.includes(numericValue)) {
                let closest = null;
                let closestDiff = Infinity;

                for (let i = 0; i < property.enum.length; i++) {
                    const currentNumber = property.enum[i];
                    const currentDiff = Math.abs(currentNumber - numericValue);

                    if (currentDiff < closestDiff) {
                        closest = currentNumber;
                        closestDiff = currentDiff;
                    }
                }
                handleChange(path, closest);
                return closest;
            }
        }
        return value;
    }

    const renderInputs = (properties, basePath = '') => {
        return Object.entries(properties).map(([key, property]) => {
            const path = basePath ? `${basePath}.${key}` : key;
            if (!excludedProperties.includes(key) && !property.hidden) {
                return renderInput(path, key, property);
            }
        });
    };

    const renderInput = (path, key, property) => {
        const value = formData[path];
        let type = Array.isArray(property.type) && property.items?.type !== InputType.Object ? property.type[0] : property.type;
        switch (type) {
            case InputType.Number:
            case InputType.Text:
                return (property.enum
                    ? <FormControl fullWidth key={path} margin="dense">
                        <InputLabel id={`${path}_label`}>{property.label || path}</InputLabel>
                        <Select
                            labelId={`${path}_label`}
                            value={findClosestMatch(value, property, path) || property.default || ''}
                            onChange={(e) => handleChange(path, e.target.value)}
                            variant="outlined"
                            fullWidth
                            size="small"
                        >
                            {property.enum.map((optionValue) => (
                                <MenuItem key={optionValue} value={optionValue}>
                                    {getOptionDisplayValue(optionValue, property)}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    : <TextField
                        key={path}
                        label={property.label || path}
                        type={type === InputType.Text ? 'text' : type}
                        value={value || property.default || ''}
                        onChange={(e) => handleChange(path, e.target.value)}
                        variant="outlined"
                        fullWidth
                        size="small"
                        margin="dense"
                    />
                );
            case InputType.Array:
                return (
                    <Box key={path} sx={{ mt: 1 }}>
                        <Grid container spacing={1} alignItems="flex-end">
                            <Grid item xs={12}>
                                <InputLabel sx={{ mb: 0, display: 'block' }}>
                                    {property.label || path}
                                    <Button
                                        onClick={() => addArrayField(path)}
                                        startIcon={<AddCircleOutlineIcon />}
                                        color="primary"
                                        sx={{ float: 'right' }}
                                    >
                                        Add
                                    </Button>
                                </InputLabel>
                            </Grid>
                            {(formData[path] || []).map((value, index) => (
                                <Grid key={`${path}[${index}]`} item xs={12} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <TextField
                                        fullWidth
                                        label={`${property.label || path} ${index + 1}`}
                                        type="text"
                                        value={value}
                                        onChange={(e) => handleArrayChange(path, e.target.value, index)}
                                        variant="outlined"
                                        size="small"
                                    />
                                    <IconButton onClick={() => removeArrayField(path, index)} color="error">
                                        <RemoveCircleOutlineIcon />
                                    </IconButton>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                );
            case InputType.Object:
                if (property.object_format === 'pointcloud_coordinates_object') {
                    return (
                        <MeasurementInputControl
                            key={path}
                            assetId={asset?.asset_uuid}
                            propertyKey={key}
                            property={property}
                            handleChange={(path, value) => handleChange(path, value)}
                        />
                    );
                }
            default:
                return null; // For unsupported types
        }
    };

    return (
        <Container>
            {schema && renderInputs(schema.json_schema_definition.properties)}
        </Container>
    );
};

export default AssetForm;